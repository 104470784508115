// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA2bL2Ueoo3TMH4oinkuzztWiFhjbVmRG8',
  authDomain: 'cafipago.firebaseapp.com',
  projectId: 'cafipago',
  storageBucket: 'cafipago.appspot.com',
  messagingSenderId: '1034799566712',
  appId: '1:1034799566712:web:46385d66d225554457028d',
  measurementId: 'G-8N3GKDGZDC',
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp)
