import {db} from '../firebase'

import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'

export function compGetUserData() {
  async function getUserData(uid) {
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }
  async function getAdminUsers() {
    const q = query(collection(db, 'users'), where('type', '!=', 'parent'))
    const querySnapshot = await getDocs(q)
    let users = []
    querySnapshot.forEach(doc => {
      let u = doc.data()
      u.id = doc.id
      users.push(u)
    })
    return users
  }
  return {
    getUserData,
    getAdminUsers,
  }
}
