import {db} from '../firebase'

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore'

export function compGetRecharges() {
  async function getParentRecharges(uid) {
    const q = query(collection(db, 'recharges'), where('owner', '==', uid))
    const querySnapshot = await getDocs(q)
    let recharges = []
    querySnapshot.forEach(doc => {
      let r = doc.data()
      r.id = doc.id
      recharges.push(r)
    })
    return recharges.sort((a, b) => {
      if (a.date < b.date) return 1
      if (a.date > b.date) return -1
      return 0
    })
  }
  async function getAllRecharges() {
    const querySnapshot = await getDocs(collection(db, 'recharges'))
    let recharges = []
    querySnapshot.forEach(doc => {
      let r = doc.data()
      r.id = doc.id
      recharges.push(r)
    })
    return recharges.sort((a, b) => {
      if (a.date < b.date) return 1
      if (a.date > b.date) return -1
      return 0
    })
  }
  async function getRechargeById(id) {
    const docRef = doc(db, 'recharges', id)
    let docSnap = await getDoc(docRef)
    let i = docSnap.id
    docSnap = docSnap.data()
    docSnap.id = i
    return docSnap
  }
  return {
    getParentRecharges,
    getAllRecharges,
    getRechargeById,
  }
}
