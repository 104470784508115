import script from "./ParentsHomeView.vue?vue&type=script&setup=true&lang=js"
export * from "./ParentsHomeView.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBadge,QIcon,QCarousel,QCarouselSlide,QImg});
