import {db} from '../firebase'

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore'

export function compGetStudents() {
  async function getParentStudents(uid) {
    const q = query(collection(db, 'students'), where('parentId', '==', uid))
    const querySnapshot = await getDocs(q)
    let students = []
    querySnapshot.forEach(doc => {
      let s = doc.data()
      s.id = doc.id
      students.push(s)
    })
    return students
  }
  async function getAllStudents() {
    const querySnapshot = await getDocs(collection(db, 'students'))
    let students = []
    querySnapshot.forEach(doc => {
      let s = doc.data()
      s.id = doc.id
      students.push(s)
    })
    return students
  }
  async function getStudentById(id) {
    const docRef = doc(db, 'students', id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) return docSnap.data()
    else return 'no-exists'
  }
  async function getStudentByRFIDTag(rfidTag) {
    const q = query(
      collection(db, 'students'),
      where('rfidTag', '==', `${rfidTag}`)
    )
    const querySnapshot = await getDocs(q)
    let std
    querySnapshot.forEach(student => {
      std = student.data()
      std.id = student.id
    })
    return std
  }
  return {
    getParentStudents,
    getAllStudents,
    getStudentById,
    getStudentByRFIDTag,
  }
}
