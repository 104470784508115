import {db} from '../firebase'

import {doc, updateDoc} from 'firebase/firestore'

export function compUpdateOrder() {
  async function completeOrderById(id) {
    const orderRef = doc(db, 'orders', id)
    await updateDoc(orderRef, {status: 'delivered'})
    return 'ok'
  }
  return {
    completeOrderById,
  }
}
