import {db} from '../firebase'

import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {collection, addDoc} from 'firebase/firestore'

export function compPostMenu() {
  async function postMenuItem(menuData, img) {
    // 1. post image
    var downloadUrl
    if (img) {
      const storage = getStorage()
      const path = `menuImgs/${img.lastModified}-${img.name}`
      const menuImageRef = ref(storage, path)
      await uploadBytes(menuImageRef, img)
      downloadUrl = await getDownloadURL(ref(storage, path))
    }
    // 2. create menu object
    const menu = {
      name: menuData.name.trim(),
      description: menuData.description.trim(),
      kidPrice: parseFloat(menuData.kidPrice),
      adultPrice: parseFloat(menuData.adultPrice),
      babyPrice: parseFloat(menuData.babyPrice),
      date: menuData.date,
      img: img ? downloadUrl : '',
    }
    // 4. post new menu
    const docRef = await addDoc(collection(db, 'menu'), menu)
    // 5. return new menu
    menu.id = docRef.id
    return menu
  }
  return {
    postMenuItem,
  }
}
