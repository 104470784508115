<template>
  <q-page style="background-image: url('https://cafipago.com/pattern.png')">
    <div class="row justify-center q-pt-lg">
      <q-icon name="restaurant_menu" size="6em" color="white" />
    </div>
    <div class="row justify-center q-pt-md">
      <div class="text-h4 text-white cp-700">CafiPago</div>
    </div>
    <div class="row justify-center q-py-lg">
      <div class="col-xl-5 col-lg-5 col-md-7 col-sm-8 col-xs-11">
        <q-card flat class="cp-rounded-borders">
          <q-card-section>
            <div class="text-h5 text-center cp-600">
              Registro de {{ step == 0 ? 'acudiente' : 'estudiante' }}
            </div>
            <div class="text-caption text-center cp-300" v-if="step == 0">
              Ingresa tus datos para crear tu cuenta.
            </div>
            <div class="text-caption text-center cp-300" v-if="step == 1">
              Ingresa los datos de los estudiantes que deseas afiliar al sistema
              CafiPago.
            </div>
          </q-card-section>
          <q-separator />
          <q-card-section v-if="step == 0">
            <q-input
              label="Nombre *"
              filled
              class="q-mb-sm"
              v-model="userInfo.userName"
              :rules="[val => !!val.trim() || 'Este campo es obligatorio']"
              hide-bottom-space
            />
            <q-input
              label="Apellido *"
              filled
              class="q-mb-sm"
              v-model="userInfo.userLastName"
              :rules="[val => !!val.trim() || 'Este campo es obligatorio']"
              hide-bottom-space
            />
            <q-input
              label="Correo electrónico *"
              type="email"
              filled
              class="q-mb-sm"
              v-model="userInfo.email"
              :rules="[
                val =>
                  validEmail.test(val.trim()) || 'Formato de correo invalido',
              ]"
              hide-bottom-space
            />
            <q-input
              label="Contraseña *"
              :type="isPwd ? 'password' : 'text'"
              filled
              class="q-mb-sm"
              v-model="userInfo.password"
              :rules="[
                val =>
                  val.trim().length >= 6 ||
                  'La contraseña debe contener al menos 6 caracteres',
              ]"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
            <q-input
              label="Confirmar contraseña *"
              :type="isPwd ? 'password' : 'text'"
              type="password"
              filled
              class="q-mb-sm"
              v-model="userInfo.repassword"
              :rules="[
                val =>
                  val == userInfo.password || 'Las contraseñas no coinciden',
              ]"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section v-if="step == 1">
            <div v-for="(child, i) in userInfo.children" :key="child">
              <div class="row q-mb-sm">
                <div class="col q-pr-xs">
                  <q-input
                    label="Nombre * "
                    filled
                    v-model="child.name"
                    :rules="[
                      val => !!val.trim() || 'Este campo es obligatorio',
                    ]"
                    hide-bottom-space
                  />
                </div>
                <div class="col q-pl-xs">
                  <q-input
                    label="Apellido * "
                    filled
                    v-model="child.lastName"
                    :rules="[
                      val => !!val.trim() || 'Este campo es obligatorio',
                    ]"
                    hide-bottom-space
                  />
                </div>
              </div>
              <div class="row q-mb-sm">
                <div class="col">
                  <q-input
                    label="Cédula *"
                    filled
                    v-model="child.id"
                    :rules="[
                      val => !!val.trim() || 'Este campo es obligatorio',
                    ]"
                    hide-bottom-space
                  />
                </div>
              </div>
              <div class="row justify-end q-mb-sm">
                <q-btn
                  label="Remover"
                  icon-right="close"
                  size="sm"
                  color="red-7"
                  flat
                  :disable="userInfo.children.length <= 1"
                  @click="removeChild(i)"
                  rounded
                  no-caps
                />
              </div>
            </div>

            <div class="row justify-end">
              <q-btn
                label="Agregar estudiante"
                icon-right="add"
                @click="addChild"
                unelevated
                no-caps
                rounded
              />
            </div>
          </q-card-section>
          <q-separator />
          <q-card-actions align="center" v-if="step == 0">
            <q-btn
              label="Volver al inicio"
              icon="o_home"
              color="blue-7"
              to="/"
              rounded
              no-caps
              class="cp-700"
              unelevated
            />
            <q-btn
              label="Siguiente"
              icon-right="arrow_forward"
              color="green-7"
              @click="nextStep"
              rounded
              no-caps
              class="cp-700"
              unelevated
            />
          </q-card-actions>
          <q-card-section v-else>
            <div class="row justify-between">
              <q-btn
                label="Atras"
                unelevated
                rounded
                no-caps
                class="cp-700"
                icon="arrow_back"
                color="green-7"
                @click="step--"
              />
              <q-btn
                label="Completar registro"
                unelevated
                rounded
                no-caps
                class="cp-700"
                icon-right="done"
                color="green-7"
                @click="createUser()"
              />
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loading" persistent>
      <div>
        <div class="row">
          <div class="text-h6 text-white cp-700">
            {{ dbStatus }}
          </div>
        </div>
        <div class="row justify-center">
          <q-spinner-dots color="white" size="3em"></q-spinner-dots>
        </div>
      </div>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref} from 'vue'

import {firebaseApp, db} from '../../firebase'
import {getAuth, createUserWithEmailAndPassword} from 'firebase/auth'
import {doc, setDoc, addDoc, collection} from 'firebase/firestore'
import {getFunctions, httpsCallable} from 'firebase/functions'

import {useRouter} from 'vue-router'
import {useQuasar} from 'quasar'

const $q = useQuasar()

const router = useRouter()

const functions = getFunctions(firebaseApp)

const step = ref(0)
const validEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const isPwd = ref(true)

const nextStep = () => {
  let msg = []
  if (
    !userInfo.value.userName.trim() ||
    !userInfo.value.userLastName.trim() ||
    !userInfo.value.email.trim() ||
    !userInfo.value.password.trim() ||
    !userInfo.value.repassword.trim()
  )
    msg.push('Debes llenar los campos obligatorios.')
  if (userInfo.value.password.trim().length < 6)
    msg.push('La contraseña debe tener al menos 6 caracteres.')
  if (userInfo.value.password != userInfo.value.repassword)
    msg.push('La contraseñas no coinciden.')
  if (!validEmail.test(userInfo.value.email))
    msg.push('Debes ingresar una dirección de correo valida')
  if (msg.length > 0) {
    msg.forEach(msg => {
      $q.notify({
        message: msg,
        color: 'red-8',
        icon: 'error',
        position: 'top',
        badgeColor: 'yellow-8',
        progress: true,
        timeout: 3000,
      })
    })
    return false
  }
  step.value++
}

function addChild() {
  userInfo.value.children.push({
    name: '',
    lastName: '',
    id: '',
  })
}

function removeChild(index) {
  userInfo.value.children.splice(index, 1)
}

const userInfo = ref({
  userName: '',
  userLastName: '',
  password: '',
  repassword: '',
  email: '',
  children: [
    {
      name: '',
      lastName: '',
      id: '',
    },
  ],
})

const loading = ref(false)
const dbStatus = ref('Verificando estudiantes...')

const errMsgs = [
  'Ocurrió un error en los datos del estudiante.',
  'Alguna de las cedulas de estudiante que estas intentando registrar, no existe.',
]

const authMsgs = [
  {
    err: 'auth/email-already-in-use',
    msg: 'Este correo ya esta registrado.',
  },
]

async function createUser() {
  // 0. validate form
  let msg = []
  for (let i = 0; i < userInfo.value.children.length; i++) {
    if (
      !userInfo.value.children[i].name.trim() ||
      !userInfo.value.children[i].lastName.trim() ||
      !userInfo.value.children[i].id.trim()
    ) {
      msg.push('Debes llenar todos los campos del estudiante.')
      break
    }
  }
  if (msg.length > 0) {
    msg.forEach(msg => {
      $q.notify({
        message: msg,
        color: 'red-8',
        icon: 'error',
        position: 'top',
        badgeColor: 'yellow-8',
        progress: true,
        timeout: 3000,
      })
    })
    return
  }
  // 1. start loading
  loading.value = true
  // 2. call cloud function to check if ids exist
  // const verifyIfCanRegister = httpsCallable(
  //   functions,
  //   'verifyAccountCanBeCreated'
  // )
  // let canRegister = await verifyIfCanRegister(userInfo.value.children)
  // 3. if ids dont exist, return function and show error message
  // if (canRegister.data.resp == 'err') {
  //   loading.value = false
  //   alert(errMsgs[canRegister.data.code])
  //   return
  // }
  // 4. if ids do exist, create account in fb auth
  const auth = getAuth()
  let newUid
  dbStatus.value = 'Creando cuenta...'
  try {
    let authResp = await createUserWithEmailAndPassword(
      auth,
      userInfo.value.email,
      userInfo.value.password
    )
    newUid = authResp.user.uid
  } catch (err) {
    console.log(err.code)
    if (err.code == 'auth/email-already-in-use') alert ('Este usuario ya esta registrado.')
    else alert('Ha ocurrido un error')
    loading.value = false
    return
  }
  // 5. create account document in firestore
  dbStatus.value = 'Guardando datos de acudiente...'
  let createParent = await setDoc(doc(db, 'users', newUid), {
    name: userInfo.value.userName,
    lastName: userInfo.value.userLastName,
    email: userInfo.value.email,
    type: 'parent',
  })
  // 6. create students documents in firestore
  dbStatus.value = 'Guardando datos de estudiante...'
  userInfo.value.children.forEach(async child => {
    await addDoc(collection(db, 'students'), {
      name: child.name,
      lastName: child.lastName,
      id: child.id.toUpperCase(),
      parentId: newUid,
      parentName: userInfo.value.userName,
      parentLastname: userInfo.value.userLastName,
      balance: 0,
      isActive: true,
      allowCredit: false,
    })
  })
  // 7. call cloud function to remove student ids from document
  // dbStatus.value = 'Actualizando base de datos...'
  // const removeIds = httpsCallable(functions, 'removeIdsFromStudentsList')
  // removeIds(userInfo.value.children)
  // 8. end loading
  loading.value = false
  // 9. redirect user to /parents/home
  router.push('/parents/home')
}
</script>
