import {db} from '../firebase'

import {collection, query, where, getDocs} from 'firebase/firestore'

export function compGetSales() {
  async function getAllSales() {
    const querySnapshot = await getDocs(collection(db, 'sales'))
    let sales = []
    querySnapshot.forEach(doc => {
      let data = doc.data()
      sales.push({
        name: `${data.studentName} ${data.studentLastName}`,
        amount: `$${data.total.toFixed(2)}`,
        date: data.date,
        method: data.paymentMethod,
        cart: data.cart,
        id: doc.id,
      })
    })
    return sales.sort((a, b) => {
      if (a.date < b.date) return 1
      if (a.date > b.date) return -1
      return 0
    })
  }
  async function getParentStudensSales(id) {
    let sales = []
    const salesRef = collection(db, 'sales')
    const q = query(
      salesRef,
      where('parentId', '==', id),
      where('paymentMethod', '!=', 'order')
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(sale => {
      let s = sale.data()
      sales.push({
        student: `${s.studentName} ${s.studentLastName}`,
        total: s.total,
        date: s.date,
        cart: s.cart,
      })
    })
    return sales
  }
  return {
    getAllSales,
    getParentStudensSales,
  }
}
