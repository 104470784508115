import {db} from '../firebase'

import {collection, query, where, getDocs} from 'firebase/firestore'

const isElectron = require('is-electron')

const fs = isElectron() ? window.require('fs').promises : {}

export function compFingerprint() {
  async function createFingerprintsDbFile() {
    const querySnapshot = await getDocs(collection(db, 'students'))
    let studentsFileDbData = ''
    querySnapshot.forEach(doc => {
      let s = `${doc.id},${doc.data().fpTemplate};`
      studentsFileDbData += s
    })
    fs.writeFile(
      'c:\\Lector de Huella\\students.txt',
      studentsFileDbData,
      err => {
        if (err) console.log(err)
      }
    )
  }
  async function readFoundStudent() {
    const id = await fs.readFile(
      'c:\\Lector de Huella\\foundStudent.txt',
      'utf8',
      (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        return data
      }
    )
    return id
  }
  async function readScannedTemplate() {
    const template = await fs.readFile(
      'c:\\Lector de Huella\\scannedTemplate.txt',
      'utf8',
      (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        return data
      }
    )
    return template
  }
  async function readScannedTemplateImg() {
    const img = await fs.readFile(
      'c:\\Lector de Huella\\scannedTemplate.jpg',
      'base64',
      (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        return data
      }
    )
    return img
  }
  return {
    createFingerprintsDbFile,
    readFoundStudent,
    readScannedTemplate,
    readScannedTemplateImg,
  }
}
