<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Crear pedido</div>
      <div class="text-body2 cp-300">
        Crea pedidos para retirar en la semana.
      </div>
    </div>
    <div class="q-pa-md" v-if="student">
      <q-banner class="bg-blue-8 text-white cp-rounded-borders">
        <div class="text-subtitle2 cp-300">
          {{ `${student.name} ${student.lastName}` }}
        </div>
        <div class="text-h6 cp-700">
          Balance actual: ${{ student.balance.toFixed(2) }}
        </div>
      </q-banner>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-12 q-pa-md">
        <div class="text-h5 text-blue-8 cp-700">1.</div>
        <q-select
          v-model="selectedPickupPlace"
          label="Seleccione lugar de entrega"
          filled
          color="blue-8"
          :options="[
            {label: 'Maternal', value: 'Maternal'},
            {label: 'Primaria', value: 'Primaria'},
            {
              label: 'Secundaria (Próximamente)',
              value: 'Secundaria',
              disable: true,
            },
          ]"
          emit-value
          map-options
        />
      </div>
      <div class="col-lg-4 col-xs-12 q-pa-md">
        <div class="text-h5 text-blue-8 cp-700">2.</div>
        <q-select
          label="Seleccione la fecha de entrega"
          filled
          color="blue-8"
          v-model="selectedDate"
          :options="days"
          emit-value
          map-options
        />
      </div>
      <div class="col-lg-4 col-xs-12 q-pa-md">
        <div class="text-h5 text-blue-8 cp-700">3.</div>
        <q-select
          v-model="selectedPickupTime"
          label="Seleccione hora de entrega"
          filled
          color="blue-8"
          :options="returnDeliveryTimeOptions"
          emit-value
          map-options
        />
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-5 col-xs-12 q-pa-md">
        <div class="text-h5 text-blue-8 cp-700">4.</div>
        <q-card flat class="cp-rounded-borders">
          <q-card-section>
            <div class="text-h6 cp-600">Carrito de compras</div>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <q-list v-if="cart.length > 0">
              <q-item v-for="item in cart">
                <q-item-section avatar>
                  <q-btn
                    icon="close"
                    flat
                    round
                    color="red-8"
                    size="sm"
                    @click="cart.splice(i, 1)"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ item.name }}</q-item-label>
                  <q-item-label caption>{{ item.price }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                  <div class="row">
                    <q-btn
                      icon="remove"
                      round
                      color="blue-8"
                      size="xs"
                      flat
                      @click="item.amount--"
                      :disable="item.amount == 1"
                    />
                    <span class="q-px-sm">{{ item.amount }}</span>
                    <q-btn
                      icon="add"
                      round
                      color="blue-8"
                      size="xs"
                      flat
                      @click="item.amount++"
                    />
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
            <div class="text-caption text-grey-5 text-center" v-else>
              No tienes artículos en tu carrito.
            </div>
          </q-card-section>
          <q-separator />
          <q-card-actions align="center">
            <q-btn
              label="Agregar al carrito"
              color="green-8"
              @click="addItemDialog = true"
              no-caps
              unelevated
              class="cp-rounded-borders"
              icon-right="add"
              :disable="
                !selectedPickupPlace || !selectedDate || !selectedPickupTime
              "
            />
          </q-card-actions>
        </q-card>
      </div>
    </div>

    <q-dialog v-model="addItemDialog" persistent>
      <q-card
        style="width: 700px; max-width: 90vw; height: 80vh"
        class="cp-rounded-borders"
        flat
      >
        <q-card-section>
          <div class="text-h6 cp-700 text-center">
            {{ formatDate(selectedDate) }}
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-scroll-area style="height: 60vh; max-width: 100%">
            <q-list>
              <q-expansion-item
                group="inventorygroup"
                label="Almuerzo"
                default-opened
                header-class="bg-blue-8 text-white cp-rounded-borders q-mb-sm"
                expand-icon-class="text-white"
              >
                <q-card>
                  <q-card-section v-if="dayMenu" class="q-px-none">
                    <q-list bordered separator class="cp-rounded-borders">
                      <q-item>
                        <q-item-section>
                          <q-item-label
                            ><span class="cp-700">Menu adulto</span> -
                            {{ dayMenu.name }}</q-item-label
                          >
                          <q-item-label caption
                            >${{ dayMenu.adultPrice }}</q-item-label
                          >
                        </q-item-section>
                        <q-item-section avatar>
                          <q-btn
                            icon="add"
                            round
                            size="sm"
                            unelevated
                            color="green-8"
                            @click="addToCart(dayMenu, dayMenu.adultPrice)"
                          />
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <q-item-label
                            ><span class="cp-700">Menu niño</span> -
                            {{ dayMenu.name }}</q-item-label
                          >
                          <q-item-label caption
                            >${{ dayMenu.kidPrice }}</q-item-label
                          >
                        </q-item-section>
                        <q-item-section avatar>
                          <q-btn
                            icon="add"
                            round
                            size="sm"
                            unelevated
                            color="green-8"
                            @click="addToCart(dayMenu, dayMenu.kidPrice)"
                          />
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <q-item-label
                            ><span class="cp-700">Menu prescolar</span> -
                            {{ dayMenu.name }}</q-item-label
                          >
                          <q-item-label caption
                            >${{ dayMenu.babyPrice }}</q-item-label
                          >
                        </q-item-section>
                        <q-item-section avatar>
                          <q-btn
                            icon="add"
                            round
                            size="sm"
                            unelevated
                            color="green-8"
                            @click="addToCart(dayMenu, dayMenu.babyPrice)"
                          />
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
              <div v-for="category in productCategories()">
                <q-expansion-item
                  group="inventorygroup"
                  :label="category.label"
                  header-class="bg-blue-8 text-white cp-rounded-borders q-mb-sm"
                  expand-icon-class="text-white"
                  v-if="filterProductsByCategory(category.value).length > 0"
                >
                  <q-card>
                    <q-card-section class="q-px-none">
                      <q-list bordered separator class="cp-rounded-borders">
                        <q-item
                          v-for="item in filterProductsByCategory(
                            category.value
                          )"
                        >
                          <q-item-section>
                            <q-item-label>{{ item.name }}</q-item-label>
                            <q-item-label caption
                              >${{ item.price.toFixed(2) }}</q-item-label
                            >
                          </q-item-section>
                          <q-item-section avatar>
                            <q-btn
                              icon="add"
                              round
                              size="sm"
                              unelevated
                              color="green-8"
                              @click="addToCart(item)"
                            />
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>
              </div>
            </q-list>
          </q-scroll-area>
        </q-card-section>
        <q-card-actions>
          <q-btn
            label="Cerrar"
            class="full-width cp-rounded-borders cp-700"
            color="green-8"
            no-caps
            unelevated
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <div style="padding: 35px 0" v-if="showTotalsDialog" />
    <div
      style="padding: 25px 0"
      v-if="student && student.balance < calculateTotal"
    />
    <q-dialog v-model="showTotalsDialog" seamless position="bottom">
      <q-card style="width: 350px" class="bg-blue-8 text-white" flat>
        <q-card-section class="row">
          <div class="text-subtitle2 items-center">
            Total: <span class="text-h6 cp-700">${{ calculateTotal }}</span>
          </div>
          <q-space />
          <q-btn
            label="Completar pedido"
            color="white text-blue-8"
            unelevated
            class="cp-rounded-borders cp-700"
            no-caps
            :disable="
              student.balance < calculateTotal ||
              loadingPostOrder ||
              !selectedPickupPlace ||
              !selectedDate ||
              !selectedPickupTime
            "
            :loading="loadingPostOrder"
            @click="sendOrder()"
          />
        </q-card-section>
        <q-banner
          class="bg-red-7 text-white"
          v-if="student.balance < calculateTotal"
        >
          No tienes balance suficiente para procesar el pedido.
        </q-banner>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount, computed, watch} from 'vue'
import moment from 'moment'
import {useRoute, useRouter} from 'vue-router'

import {compDataFormat} from '@/composables/dataFormat'
import {compGetInventory} from '@/composables/getInventoryItems'
import {compGetMenu} from '@/composables/getMenu'
import {compGetStudents} from '@/composables/getStudents'
import {compPostOrder} from '@/composables/postOrder'

import {useQuasar} from 'quasar'

const $q = useQuasar()

const {formatDate, productCategories} = compDataFormat()
const {getAllInventory} = compGetInventory()
const {getMenuByDate} = compGetMenu()
const {getStudentById} = compGetStudents()
const {postOrder} = compPostOrder()

const route = useRoute()
const router = useRouter()

let today = moment()
if (today.weekday() == 6) today = moment(today).add(2, 'days')
if (today.weekday() == 0) today = moment(today).add(1, 'days')
const weekStart = today.clone().startOf('isoWeek').format('YYYY/MM/DD')
let days = ref([])
for (let i = 1; i <= 4; i++) {
  let nDay = moment(weekStart).add(i, 'days').format('YYYY/MM/DD')
  let maxDate = moment(nDay).subtract(6, 'hours')
  if (!today.isSameOrAfter(maxDate)) {
    days.value.push({
      label: formatDate(nDay),
      value: nDay,
    })
  }
}

const inventory = ref([])
const addItemDialog = ref(false)
const dayMenu = ref(null)
const student = ref(null)
const loadingPostOrder = ref(false)
//
const selectedDate = ref('')
const cart = ref([])
const selectedPickupPlace = ref('')
const selectedPickupTime = ref('')

const addToCart = (item, menuPrice) => {
  if (!menuPrice) {
    cart.value.push({
      barcode: item.barcode,
      name: item.name,
      price: item.price,
      amount: 1,
    })
  } else {
    cart.value.push({
      name: item.name,
      description: item.description,
      price: menuPrice,
      amount: 1,
    })
  }
  $q.notify({
    message: 'Artículo agregado con éxito.',
    color: 'green-8',
    icon: 'check',
    position: 'top',
    badgeColor: 'yellow-8',
    progress: true,
    timeout: 3000,
  })
}

const filterProductsByCategory = category => {
  return inventory.value.filter(item => item.category == category)
}

const sendOrder = async () => {
  loadingPostOrder.value = true
  const orderResp = await postOrder(
    route.params.studentId,
    cart.value,
    selectedPickupPlace.value,
    selectedDate.value,
    selectedPickupTime.value
  )
  loadingPostOrder.value = false
  if (orderResp == 'ok') {
    $q.notify({
      message: 'Orden enviada con éxito.',
      color: 'green-8',
      icon: 'check',
      position: 'top',
      badgeColor: 'yellow-8',
      progress: true,
      timeout: 3000,
    })
    router.push('/parents/orders')
  }
}

const calculateTotal = computed(() => {
  let total = 0
  cart.value.forEach(item => {
    total += item.amount * item.price
  })
  return total.toFixed(2)
})

const showTotalsDialog = computed(() => {
  if (addItemDialog.value) return false
  return calculateTotal.value > 0
})

const returnDeliveryTimeOptions = computed(() => {
  if (selectedPickupPlace.value == 'Maternal')
    return ['1er Recreo', '2do Recreo', 'Almuerzo']
  if (selectedPickupPlace.value == 'Primaria') return ['Recreo', 'Almuerzo']
  if (selectedPickupPlace.value == 'Secundaria') return []
})

watch(selectedDate, async () => {
  dayMenu.value = await getMenuByDate(selectedDate.value)
  cart.value = []
})

watch(selectedPickupPlace, async () => {
  selectedPickupTime.value = ''
})

onBeforeMount(async () => {
  inventory.value = await getAllInventory()
  student.value = await getStudentById(route.params.studentId)
})
</script>
