<template>
  <q-page v-if="isSelling">
    <div class="q-pa-md">
      <div class="text-h4 cp-700">POS</div>
      <div class="text-body2 cp-300">Realiza ventas a tus clientes.</div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-xs-12 q-px-md">
        <div class="row">
          <div class="col-lg-8 q-pr-sm">
            <q-card flat class="cp-rounded-borders q-mb-md">
              <q-card-section>
                <q-input
                  label="Código de barra"
                  filled
                  color="blue-8"
                  bottom-slots
                  ref="barcodeInput"
                  v-model="barcodeValue"
                  type="number"
                  clearable
                  @keydown.enter="findItem"
                >
                  <template v-slot:after>
                    <q-btn
                      round
                      dense
                      flat
                      icon="send"
                      size="lg"
                      color="blue-8"
                      @click="findItem"
                      :disable="findItemLoading"
                      :loading="findItemLoading"
                    />
                  </template>
                  <template v-slot:hint>
                    Agregar articulo por codigo de barra
                  </template>
                </q-input>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-lg-2 q-pl-sm">
            <q-card flat class="cp-rounded-borders">
              <q-card-section>
                <q-btn
                  color="yellow-8"
                  unelevated
                  class="cp-rounded-borders full-width"
                  icon="fas fa-child-reaching"
                  size="xl"
                  @click="addTodayMenuToCart('kid')"
                />
              </q-card-section>
            </q-card>
          </div>
          <div class="col-lg-2 q-pl-sm">
            <q-card flat class="cp-rounded-borders">
              <q-card-section>
                <q-btn
                  color="blue-8"
                  unelevated
                  class="cp-rounded-borders full-width"
                  icon="fas fa-person"
                  size="xl"
                  @click="addTodayMenuToCart('adult')"
                />
              </q-card-section>
            </q-card>
          </div>
        </div>

        <q-card flat class="cp-rounded-borders">
          <q-card-section>
            <div class="text-h3 cp-bold text-right">
              ${{ formatCustomAmount(customAmount) }}
            </div>
          </q-card-section>
          <q-card-section>
            <div class="row">
              <div class="col-4 q-pa-sm" v-for="num in 9">
                <q-btn
                  class="full-width cp-rounded-borders q-py-md"
                  unelevated
                  color="blue-8"
                  outline
                  @click="appendCustomAmountValue(num)"
                >
                  <span class="text-h5 cp-700">{{ num }} </span>
                </q-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-4 q-pa-sm">
                <q-btn
                  class="full-width cp-rounded-borders q-py-md"
                  unelevated
                  color="yellow-8"
                  outline
                  @click="deleteFromCustomAmount()"
                >
                  <q-icon name="backspace" size="2.3em" />
                </q-btn>
              </div>
              <div class="col-4 q-pa-sm">
                <q-btn
                  class="full-width cp-rounded-borders q-py-md"
                  unelevated
                  color="blue-8"
                  outline
                  @click="appendCustomAmountValue('0')"
                >
                  <span class="text-h5 cp-700">0 </span>
                </q-btn>
              </div>
              <div class="col-4 q-pa-sm">
                <q-btn
                  class="full-width cp-rounded-borders q-py-md"
                  unelevated
                  color="green-8"
                  @click="addOtherItem"
                  :disable="disableAddOtherItemBtn"
                >
                  <q-icon name="send" size="2.3em" />
                </q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-lg-6 col-xs-12 q-px-md">
        <q-card flat class="cp-rounded-borders">
          <q-card-section>
            <div class="text-h5 cp-700">
              Esta venta:
              <span v-if="selectedPaymentMethod == 'fingerprint'">Huella</span>
              <span v-if="selectedPaymentMethod == 'cash'">Efectivo</span>
              <span v-if="selectedPaymentMethod == 'yappy'">Yappy</span>
              <span v-if="selectedPaymentMethod == 'rfid'">Tarjeta RFID</span>
            </div>
          </q-card-section>
          <q-separator />
          <div v-if="queryStudent">
            <q-card-section>
              <div class="row items-center">
                <q-btn
                  icon="fingerprint"
                  color="blue-8"
                  unelevated
                  class="cp-rounded-borders"
                  size="2em"
                  @click="reScanFingerprint"
                  v-if="selectedPaymentMethod == 'fingerprint'"
                />
                <div class="q-pl-md text-h6">
                  <div>
                    Estudiante:
                    <span class="cp-700 text-blue-8">{{
                      `${queryStudent.name} ${queryStudent.lastName}`
                    }}</span>
                  </div>
                  <div>
                    Balance:
                    <span class="cp-700 text-blue-8"
                      >${{ queryStudent.balance.toFixed(2) }}</span
                    >
                  </div>
                  <div>
                    Balance post-venta:
                    <span class="cp-700 text-blue-8"
                      >${{
                        (queryStudent.balance - calculateTotal).toFixed(2)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </q-card-section>
            <q-separator />
          </div>
          <q-card-section>
            <div class="text-center" v-if="cart.length == 0">
              No hay articulos en el carrito
            </div>
            <div
              class="row items-center q-mb-md"
              v-for="(item, i) in cart"
              :key="item.barcode"
            >
              <div class="col-lg-6">
                <div class="text-subtitle2 cp-600">{{ item.name }}</div>
                <div class="text-caption cp-400">
                  {{ item.description }}
                </div>
              </div>
              <div class="col-2 text-center">
                <div class="text-subtitle2 cp-600">
                  {{ item.amount }}
                </div>
              </div>
              <div class="col-3 text-center">
                <div class="text-subtitle2 cp-600">
                  ${{ (item.amount * item.price).toFixed(2) }}
                </div>
              </div>
              <div class="col-1">
                <q-btn
                  :icon="item.amount > 1 ? 'remove' : 'close'"
                  color="red-8"
                  size="md"
                  round
                  unelevated
                  @click="removeItemFromCart(i)"
                />
              </div>
            </div>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <div class="text-h5 cp-600 text-right">
              Total:
              <span class="cp-700 text-blue-8">${{ calculateTotal }}</span>
            </div>
          </q-card-section>
          <q-card-actions>
            <slide-unlock
              class="full-width q-mb-md"
              ref="saleSliderRef"
              :auto-width="true"
              :circle="true"
              :disabled="cart.length == 0"
              :noanimate="true"
              :height="60"
              text="Completar venta"
              success-text="Procesando venta"
              @completed="processSale()"
            />
            <q-btn
              label="Cancelar venta"
              color="red-8"
              class="full-width cp-700"
              unelevated
              rounded
              outline
              icon="close"
              @click="resetSale"
            />
          </q-card-actions>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="queryStudentDialog" persistent>
      <q-card
        flat
        class="cp-rounded-borders"
        style="width: 500px; max-width: 80vw"
      >
        <q-separator />
        <q-card-section v-if="isRoe">
          <div class="cp-rounded-borders q-py-xl text-center">
            <div class="text-h6 text-blue-8 cp-700 q-mb-md">
              Coloque el dedo sobre el lector
            </div>
            <q-spinner-rings color="blue-8" size="10em" />
          </div>
        </q-card-section>

        <div v-if="!selectedPaymentMethod || selectedPaymentMethod == 'rfid'">
          <q-card-section class="q-pb-none">
            <div class="row">
              <q-btn
                class="full-width cp-rounded-borders"
                unelevated
                color="yellow-8"
                size="3em"
                @click="selectRFIDSale()"
                no-caps
              >
                <q-icon name="contactless" />
                <div class="text-h6 q-px-md">Pago con tarjeta</div>
              </q-btn>
            </div>
          </q-card-section>
          <q-card-section
            class="q-pb-none"
            v-if="selectedPaymentMethod == 'rfid'"
          >
            <q-input
              v-model="rfidValue"
              label="ID de tarjeta estudiantil"
              filled
              color="blue-8"
              ref="rfidInput"
              type="number"
              @keydown.enter="searchStudentByRFID()"
            />
            <div class="row q-my-md">
              <div class="col q-pr-sm">
                <q-btn
                  label="Atras"
                  class="full-width cp-rounded-borders cp-700"
                  outline
                  color="red-7"
                  @click="selectedPaymentMethod = ''"
                />
              </div>
              <div class="col">
                <q-btn
                  label="Buscar estudiante"
                  class="full-width cp-rounded-borders cp-700"
                  outline
                  color="blue-8"
                  @click="searchStudentByRFID()"
                  :disable="!rfidValue || loadingSearchRFIDStudent"
                  :loading="loadingSearchRFIDStudent"
                />
              </div>
            </div>
          </q-card-section>
          <q-card-section v-if="selectedPaymentMethod != 'rfid'">
            <div class="row">
              <div class="col q-pr-sm">
                <q-btn
                  class="full-width cp-rounded-borders"
                  unelevated
                  color="green-8"
                  size="3em"
                  @click="selectCashSale('cash')"
                  no-caps
                >
                  <q-icon name="payments" />
                  <div class="text-h6 q-px-md">Pago en efectivo</div>
                </q-btn>
              </div>
              <div class="col q-pl-sm">
                <q-btn
                  class="full-width cp-rounded-borders"
                  unelevated
                  color="blue-8"
                  size="3em"
                  @click="selectCashSale('yappy')"
                  no-caps
                >
                  <q-icon name="account_balance" />
                  <div class="text-h6 q-px-md">Pago con Yappy</div>
                </q-btn>
              </div>
            </div>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <q-btn
              label="Cerrar módulo de venta"
              class="full-width cp-700 cp-rounded-borders"
              color="red-8"
              outline
              @click="isSelling = false"
            />
          </q-card-section>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="changeDialog">
      <q-card
        flat
        class="cp-rounded-borders"
        style="width: 500px; max-width: 80vw"
      >
        <q-card-section>
          <div class="text-h6 cp-700">
            Calcular vuelto:
            <span class="text-blue-8"
              >${{ formatCustomAmount(clientPays) }}</span
            >
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="row q-mb-md">
            <div class="col-lg-3 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                color="blue-8"
                unelevated
                @click="calculateFixedAmountChange('100')"
              >
                <span class="text-h5 cp-700">$1</span>
              </q-btn>
            </div>
            <div class="col-lg-3 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                color="blue-8"
                unelevated
                @click="calculateFixedAmountChange('500')"
              >
                <span class="text-h5 cp-700">$5</span>
              </q-btn>
            </div>
            <div class="col-lg-3 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                color="blue-8"
                unelevated
                @click="calculateFixedAmountChange('1000')"
              >
                <span class="text-h5 cp-700">$10</span>
              </q-btn>
            </div>
            <div class="col-lg-3 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                color="blue-8"
                unelevated
                @click="calculateFixedAmountChange('2000')"
              >
                <span class="text-h5 cp-700">$20</span>
              </q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-4 q-pa-sm" v-for="num in 9">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                unelevated
                color="blue-8"
                outline
                @click="appendCustomAmountValue(num, true)"
              >
                <span class="text-h5 cp-700">{{ num }}</span>
              </q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-4 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                unelevated
                color="yellow-8"
                outline
                @click="deleteFromCustomAmount(true)"
              >
                <q-icon name="backspace" size="2.3em" />
              </q-btn>
            </div>
            <div class="col-4 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                unelevated
                color="blue-8"
                outline
                @click="appendCustomAmountValue('0', true)"
              >
                <span class="text-h5 cp-700">0</span>
              </q-btn>
            </div>
            <div class="col-4 q-pa-sm">
              <q-btn
                class="full-width cp-rounded-borders q-py-md"
                unelevated
                color="green-8"
                :disable="disableCalculateChangeBtn"
                @click="showChange = true"
              >
                <q-icon name="send" size="2.3em" />
              </q-btn>
            </div>
          </div>
        </q-card-section>
        <q-separator v-if="showChange" />
        <q-card-section v-if="showChange">
          <div class="text-center text-h5 cp-700 q-mb-md">
            Vuelto:
            <span class="text-blue-8">${{ calculateChange.toFixed(2) }}</span>
          </div>
          <q-btn
            label="Completar venta"
            color="green-8"
            size="lg"
            class="cp-rounded-borders full-width"
            no-caps
            unelevated
            v-close-popup
            @click="resetSale"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
  <q-page v-else>
    <div class="absolute-center">
      <div class="row justify-center">
        <q-icon
          name="restaurant_menu"
          color="blue-8"
          size="20em"
          class="q-mb-md"
        />
      </div>
      <div class="row justify-center">
        <q-btn
          label="Iniciar módulo de venta"
          outline
          color="blue-8"
          class="cp-rounded-borders cp-700"
          size="xl"
          @click="isSelling = true"
        />
      </div>
    </div>
  </q-page>
</template>

<script setup>
import {ref, computed, watch, onBeforeUnmount} from 'vue'
import moment from 'moment'
import SlideUnlock from 'vue-slide-unlock'

import {compGetInventory} from '@/composables/getInventoryItems'
import {compGetMenu} from '@/composables/getMenu'
import {compFingerprint} from '@/composables/fingerprintHandler'
import {compGetStudents} from '@/composables/getStudents'
import {compPostSales} from '@/composables/postSales'

import {useQuasar} from 'quasar'

const $q = useQuasar()

let isElectron = require('is-electron')
var fileWatcher, isRoe
if (isElectron()) {
  isRoe = true
  fileWatcher = window.require('chokidar')
}

const {getInventoryItemById} = compGetInventory()
const {getMenuByDate} = compGetMenu()
const {getStudentById, getStudentByRFIDTag} = compGetStudents()
const {createFingerprintsDbFile, readFoundStudent} = compFingerprint()
const {postSale} = compPostSales()

const barcodeValue = ref('')
const barcodeInput = ref(null)
const customAmount = ref('')
const findItemLoading = ref(false)
const cart = ref([])
const today = moment().format('YYYY/MM/DD')
const todayMenu = ref(null)
const selectedPaymentMethod = ref('')
const queryStudent = ref(null)
const queryStudentDialog = ref(true)
const saleSliderRef = ref(null)
const isSelling = ref(false)
const templateWatcher = ref(null)
const changeDialog = ref(false)
const clientPays = ref('')
const showChange = ref(false)
const rfidInput = ref(null)
const rfidValue = ref('')
const loadingSearchRFIDStudent = ref(false)

const findItem = async () => {
  if (!barcodeValue.value) return
  findItemLoading.value = true
  const itemResp = await getInventoryItemById(barcodeValue.value)
  findItemLoading.value = false
  if (itemResp == 'no-exists') {
    $q.notify({
      message: 'No se pudo encontrar este articulo.',
      color: 'red-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'yellow-8',
      progress: true,
      timeout: 3000,
    })
    barcodeValue.value = ''
    return
  }
  addItemToCart(itemResp)
  barcodeValue.value = ''
}

const addItemToCart = addItem => {
  const itemExistsInCart = cart.value.find(item => item.id == addItem.id)
  if (itemExistsInCart) {
    itemExistsInCart.amount++
  } else {
    addItem.amount = 1
    cart.value.push(addItem)
  }
}

const getTodayMenu = async () => {
  const menuResp = await getMenuByDate(today)
  todayMenu.value = menuResp
}

const addTodayMenuToCart = async type => {
  if (!todayMenu.value) await getTodayMenu()
  let price
  if (type == 'kid') price = todayMenu.value.kidPrice
  if (type == 'adult') price = todayMenu.value.adultPrice
  cart.value.push({
    name: todayMenu.value.name,
    description: todayMenu.value.description,
    price: price,
    amount: 1,
  })
}

const addOtherItem = () => {
  let customPrice = formatCustomAmount(customAmount.value)
  customPrice = parseFloat(customPrice)
  if (customPrice <= 0) return
  cart.value.push({
    name: 'Otros',
    amount: 1,
    price: customPrice,
  })
  customAmount.value = ''
}

const removeItemFromCart = index => {
  if (cart.value[index].amount == 1) cart.value.splice(index, 1)
  else cart.value[index].amount--
}

const appendCustomAmountValue = (val, change) => {
  if (change) clientPays.value += val
  else customAmount.value += val
}

const formatCustomAmount = amount => {
  let formatted
  if (amount.length < 2) formatted = parseFloat(`0.0${amount}`)
  if (amount.length >= 2 && amount.length < 3)
    formatted = parseFloat(`0.${amount}`).toFixed(2)
  if (amount.length >= 3) {
    let intPart = amount.slice(0, amount.length - 2)
    let decPart = amount.slice(-2)
    formatted = parseFloat(`${intPart}.${decPart}`).toFixed(2)
  }
  return formatted
}

const deleteFromCustomAmount = change => {
  if (change) {
    clientPays.value = clientPays.value.substring(
      0,
      clientPays.value.length - 1
    )
  } else {
    customAmount.value = customAmount.value.substring(
      0,
      customAmount.value.length - 1
    )
  }
}

const focusBarcodeInput = () => {
  barcodeValue.value = ''
  barcodeInput.value.focus()
}

const reScanFingerprint = () => {
  cleanFoundStudentData()
  queryStudentDialog.value = true
}

const cleanFoundStudentData = () => {
  queryStudent.value = null
}

const selectCashSale = cashType => {
  selectedPaymentMethod.value = cashType
  queryStudentDialog.value = false
  setTimeout(() => {
    focusBarcodeInput()
  }, 100)
}

const selectRFIDSale = () => {
  selectedPaymentMethod.value = 'rfid'
  setTimeout(() => {
    rfidInput.value.focus()
  }, 100)
}

const searchStudentByRFID = async () => {
  loadingSearchRFIDStudent.value = true
  const student = await getStudentByRFIDTag(rfidValue.value)
  loadingSearchRFIDStudent.value = false
  if (!student) {
    $q.notify({
      message: 'No se encontro el estudiante.',
      color: 'red-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'yellow-8',
      progress: true,
      timeout: 3000,
    })
    rfidValue.value = ''
    rfidInput.value.focus()
    return
  }
  queryStudent.value = student
  queryStudentDialog.value = false
}

const calculateFixedAmountChange = amount => {
  clientPays.value = amount
  showChange.value = true
}

const resetSale = () => {
  changeDialog.value = false
  clientPays.value = ''
  cleanFoundStudentData()
  queryStudent.value = null
  selectedPaymentMethod.value = ''
  queryStudentDialog.value = true
  cart.value = []
  showChange.value = false
  rfidValue.value = ''
}

const calculateTotal = computed(() => {
  let total = 0
  cart.value.forEach(item => {
    total += item.amount * item.price
  })
  return parseFloat(total).toFixed(2)
})

const disableAddOtherItemBtn = computed(() => {
  let customPrice = formatCustomAmount(customAmount.value)
  customPrice = parseFloat(customPrice)
  if (customPrice <= 0) return true
  else return false
})

const disableCalculateChangeBtn = computed(() => {
  let amount = formatCustomAmount(clientPays.value)
  amount = parseFloat(amount)
  if (amount <= 0) return true
  else return false
})

const calculateChange = computed(() => {
  const change = formatCustomAmount(clientPays.value) - calculateTotal.value
  return change
})

const processSale = async () => {
  const saleResp = await postSale(
    cart.value,
    queryStudent.value,
    selectedPaymentMethod.value
  )
  if (saleResp == 'ok') {
    $q.notify({
      message: 'Venta procesada con éxito',
      color: 'green-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'white',
      badgeTextColor: 'green-8',
      progress: true,
      timeout: 3000,
    })
    if (
      selectedPaymentMethod.value == 'fingerprint' ||
      selectedPaymentMethod.value == 'yappy' ||
      selectedPaymentMethod.value == 'rfid'
    )
      resetSale()
    if (selectedPaymentMethod.value == 'cash') changeDialog.value = true
  }
  if (saleResp == 'no-balance') {
    $q.notify({
      message: 'El estudiante no tiene balance suficiente.',
      color: 'red-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'yellow-8',
      progress: true,
      timeout: 3000,
    })
  }
  saleSliderRef.value.reset()
}

const StartWatcher = path => {
  templateWatcher.value = fileWatcher.watch(path, {
    ignored: /[\/\\]\./,
    persistent: true,
  })
  templateWatcher.value.on('change', async () => {
    if (queryStudent.value) return
    let readFpFromFile = await readFoundStudent()
    let studentDataFromDb
    if (readFpFromFile == 'no-match') {
      $q.notify({
        message: 'No se pudo encontrar la huella.',
        color: 'red-8',
        icon: 'error',
        position: 'top',
        badgeColor: 'yellow-8',
        progress: true,
        timeout: 3000,
      })
    } else {
      studentDataFromDb = await getStudentById(readFpFromFile)
      studentDataFromDb.id = readFpFromFile
      queryStudent.value = studentDataFromDb
      selectedPaymentMethod.value = 'fingerprint'
      queryStudentDialog.value = false
      setTimeout(() => {
        focusBarcodeInput()
      }, 100)
    }
  })
}

watch(isSelling, () => {
  setTimeout(() => {
    if (isSelling.value) {
      focusBarcodeInput()
      if (fileWatcher) {
        createFingerprintsDbFile()
        StartWatcher('c:\\Lector de Huella\\foundStudent.txt')
      }
    }
  }, 500)
})

onBeforeUnmount(() => {
  if (templateWatcher.value) templateWatcher.value.close()
})
</script>
