import {firebaseApp, db} from '../firebase'

import {doc, getDoc, updateDoc} from 'firebase/firestore'
import {getFunctions, httpsCallable} from 'firebase/functions'

const functions = getFunctions(firebaseApp)

export function compUpdateRecharges() {
  async function updateRechargeStatus(recharge, status, reason) {
    let updateObject = {
      status: status,
    }
    if (reason) updateObject.rejectionReason = reason
    const rechargeRef = doc(db, 'recharges', recharge.id)
    await updateDoc(rechargeRef, updateObject)
    if (status == 'approved') {
      recharge.students.forEach(async student => {
        const studentRef = doc(db, 'students', student.studentId)
        const queryStudent = await getDoc(studentRef)
        let newBalance =
          queryStudent.data().balance + parseFloat(student.amount)
        await updateDoc(studentRef, {
          balance: newBalance,
        })
      })
    }
    const approveRejectEmail = httpsCallable(
      functions,
      'rechargeApproveRejectEmail'
    )
    approveRejectEmail({email: recharge.email, status: status, reason: reason})
  }
  return {
    updateRechargeStatus,
  }
}
