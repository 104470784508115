import moment from 'moment'

export function compDataFormat() {
  function formatPaymentMethod(method) {
    switch (method) {
      case 'fingerprint':
        return 'Huella'
      case 'cash':
        return 'Efectivo'
      case 'yappy':
        return 'Yappy'
      case 'order':
        return 'Pedido'
      case 'rfid':
        return 'Tarjeta RFID'
      default:
        return method
    }
  }

  function formatDate(date) {
    return moment(date).locale('es').format('ddd DD MMMM YYYY')
  }

  function formatRechargeStatus(status) {
    switch (status) {
      case 'approved':
        return {label: 'Aprobado', color: 'green-8'}
      case 'pending':
        return {label: 'Pendiente', color: 'yellow-8'}
      case 'rejected':
        return {label: 'Rechazado', color: 'red-8'}
      default:
        return {label: status, color: 'grey-8'}
    }
  }

  function formatUserType(type) {
    switch (type) {
      case 'admin':
        return 'Administrador'
      case 'cashier':
        return 'Caja'
      default:
        return type
    }
  }

  function productCategories() {
    const categories = [
      {
        value: 'breakfast',
        label: 'Desayuno',
      },
      {
        value: 'hot-drinks',
        label: 'Bebidas calientes',
      },
      {
        value: 'cold-drinks',
        label: 'Bebidas frias',
      },
      {
        value: 'snacks',
        label: 'De picar',
      },
      {
        value: 'fruits',
        label: 'Frutas',
      },
      {
        value: 'test',
        label: 'Test',
      },
    ]
    return sortOptions(categories)
  }

  function formatOptions(options, findEl) {
    if (findEl) {
      let found = options.find(option => option.value == findEl)
      if (found) return found.label
      return findEl
    }
    return ''
  }

  function formatOrderStatus(status) {
    switch (status) {
      case 'delivery':
        return {label: 'Por entregar', color: 'yellow-8'}
      case 'delivered':
        return {label: 'Entregado', color: 'green-8'}
      default:
        return {label: status, color: 'grey-8'}
    }
  }

  function sortOptions(options) {
    return options.sort((a, b) => {
      const optA = a.label.toUpperCase()
      const optB = b.label.toUpperCase()
      if (optA < optB) return -1
      if (optA > optB) return 1
      return 0
    })
  }

  return {
    formatPaymentMethod,
    formatDate,
    formatRechargeStatus,
    formatUserType,
    productCategories,
    formatOptions,
    formatOrderStatus,
  }
}
