<template>
  <q-page v-if="userType == 'admin'">
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Usuarios</div>
      <div class="text-body2 cp-300">
        Gestiona los usuarios de acceso al panel administrativo.
      </div>
      <div class="row justify-end">
        <q-btn
          label="Agregar"
          rounded
          color="green-8"
          unelevated
          no-caps
          icon-right="add"
          class="q-px-lg"
          @click="startNewUser"
        />
      </div>
    </div>

    <div class="q-px-md">
      <q-table
        :rows="allUsers"
        :filter="filter"
        :columns="columns"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
        :loading="newUserLoading"
      >
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Buscar"
            filled
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                icon="close"
                round
                color="red-8"
                unelevated
                size="xs"
                @click="deleteUser(props.row.id)"
              />
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="lastName" :props="props">
              {{ props.row.lastName }}
            </q-td>
            <q-td key="email" :props="props">
              {{ props.row.email }}
            </q-td>
            <q-td key="type" :props="props">
              {{ formatUserType(props.row.type) }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <q-dialog v-model="newUserDialog">
      <q-card flat class="cp-rounded-borders">
        <q-card-section>
          <div class="text-h6 cp-700">Crear nuevo usuario administrador</div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-input
            label="Nombre"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="userData.name"
          />
          <q-input
            label="Apellido"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="userData.lastName"
          />
          <q-input
            label="Correo electronicó"
            filled
            color="blue-8"
            class="q-mb-sm"
            type="email"
            v-model="userData.email"
          />
          <q-input
            label="Contraseña"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="userData.password"
          ></q-input>
          <q-select
            label="Nivel de acceso"
            filled
            color="blue-8"
            v-model="userData.type"
            emit-value
            map-options
            :options="[
              {label: 'Administrador', value: 'admin'},
              {label: 'Caja', value: 'cashier'},
            ]"
          />
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn
            label="Crear"
            unelevated
            color="green-8"
            class="cp-rounded-borders cp-700"
            no-caps
            @click="postNewUser"
            :loading="newUserLoading"
            :disable="newUserLoading || validateNewUserData"
          />
          <q-btn
            label="Cancelar"
            unelevated
            color="grey-8"
            class="cp-rounded-borders cp-700"
            no-caps
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue'

import {firebaseApp} from '../../firebase'

import {getFunctions, httpsCallable} from 'firebase/functions'

import {compGetUserData} from '@/composables/getUserData'
import {compDataFormat} from '@/composables/dataFormat'

const {getAdminUsers} = compGetUserData()
const {formatUserType} = compDataFormat()

const functions = getFunctions(firebaseApp)

const filter = ref('')
const userType = ref(null)
const newUserDialog = ref(false)
const userData = ref(null)
const newUserLoading = ref(true)
const allUsers = ref([])
const validEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const startNewUser = () => {
  userData.value = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    type: '',
  }
  newUserDialog.value = true
}

const postNewUser = async () => {
  newUserLoading.value = true
  const createUser = httpsCallable(functions, 'createNewAdminUser')
  const newUserResp = await createUser(userData.value)
  if (newUserResp.data.resp == 'ok') {
    let nUser = userData.value
    nUser.id = newUserResp.data.uid
    allUsers.value.unshift(nUser)
  }
  newUserLoading.value = false
  newUserDialog.value = false
}

const deleteUser = async id => {
  if (confirm('Deseas eliminar esta cuenta?')) {
    newUserLoading.value = true
    const deleteUser = httpsCallable(functions, 'deleteAdminUser')
    const delResp = await deleteUser(id)
    if (delResp.data == 'ok')
      allUsers.value = allUsers.value.filter(user => user.id != id)
    newUserLoading.value = false
  }
}

const validateNewUserData = computed(() => {
  if (
    !userData.value.name.trim() ||
    !userData.value.lastName.trim() ||
    userData.value.password.trim().length < 6 ||
    !userData.value.type ||
    !validEmail.test(userData.value.email)
  )
    return true
  return false
})

onBeforeMount(async () => {
  userType.value = sessionStorage.getItem('cpUserType')
  let usersResp = await getAdminUsers()
  allUsers.value = usersResp
  newUserLoading.value = false
})

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Nombre',
    align: 'left',
    field: 'name',
    sortable: true,
  },
  {
    name: 'lastName',
    required: true,
    label: 'Apellido',
    align: 'left',
    field: 'lastName',
    sortable: true,
  },
  {
    name: 'email',
    required: true,
    label: 'Correo',
    align: 'left',
    field: 'email',
    sortable: true,
  },
  {
    name: 'type',
    required: true,
    label: 'Tipo',
    align: 'left',
    field: 'type',
    sortable: true,
  },
]
</script>
