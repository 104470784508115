<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Recargas</div>
      <div class="text-body2 cp-300 q-mb-sm">
        Revisa y realiza recargas al balance de tus hijos. (El proceso de
        aprobación de recarga puede demorar hasta 24 horas).
      </div>
      <div class="text-body2 cp-700 text-blue-8">Información bancaria:</div>
      <div class="text-body2 cp-400">Banco General</div>
      <div class="text-body2 cp-400">Cuenta corriente</div>
      <div class="text-body2 cp-400">03-01-01-024114-8</div>
      <div class="text-body2 cp-400">STRACCETTI S.A.</div>
    </div>
    <div class="row q-px-md q-mb-md">
      <q-btn
        label="Nueva recarga"
        icon-right="add"
        color="green-8"
        rounded
        unelevated
        no-caps
        @click="rechargeDialog = true"
      />
    </div>
    <div class="q-px-md">
      <q-table
        title="Historial de recargas"
        :rows="recharges"
        :columns="columns"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
        :loading="loadingRecharges"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="blue-8"
                round
                dense
                unelevated
                @click="props.row.expand = !props.row.expand"
                :icon="props.row.expand ? 'remove' : 'add'"
              />
            </q-td>
            <q-td key="date" :props="props">
              {{ formatDate(props.row.date) }}
            </q-td>
            <q-td key="amount" :props="props">
              ${{ parseFloat(props.row.amount).toFixed(2) }}
            </q-td>
            <q-td key="status" :props="props">
              <q-badge
                :label="formatRechargeStatus(props.row.status).label"
                :color="formatRechargeStatus(props.row.status).color"
              />
            </q-td>
          </q-tr>
          <q-tr v-show="props.row.expand" :props="props">
            <q-td colspan="100%">
              <div class="text-left" v-for="student in props.row.students">
                <div v-if="props.row.rejectionReason" class="q-py-sm cp-700">
                  <span class="text-red-8">Motivo de rechazo:</span>
                  {{ props.row.rejectionReason }}
                </div>
                {{ student.studentFullName }} - ${{ student.amount.toFixed(2) }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="rechargeDialog">
      <q-card flat class="cp-rounded-borders">
        <q-card-section>
          <div class="text-h6 cp-600">Completa el formulario de recarga.</div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-input
            label="Monto total de recarga *"
            filled
            color="blue-8"
            v-model.number="newRechargeData.amount"
            type="number"
            class="q-mb-md"
            hint="Ingresa el monto total de la recarga."
            :rules="[val => !!val || 'Este campo es obligatorio']"
          >
            <template v-slot:prepend>
              <q-icon name="attach_money" />
            </template>
          </q-input>
          <q-input
            filled
            :label="`${student.studentFullName} *`"
            color="blue-8"
            class="q-mb-md"
            hint="Ingresa el monto individual de cada dependiente."
            v-for="student in newRechargeData.students"
            :key="student.id"
            v-model="student.amount"
            :rules="[val => !!val || 'Este campo es obligatorio']"
          >
            <template v-slot:prepend>
              <q-icon name="attach_money" />
            </template>
          </q-input>
          <q-file
            label="Comprobante de pago *"
            hint="Adjunta el comprobante de pago del banco."
            filled
            color="blue-8"
            v-model="newRechargeImg"
            accept="image/jpeg,image/png"
          >
            <template v-slot:prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
        </q-card-section>
        <q-card-section>
          <div class="text-caption cp-700">
            RECUERDE QUE TODO DEPÓSITO DEBE SER REALIZADO CON MÍNIMO 24 HORAS DE
            ANTICIPACIÓN
          </div>
        </q-card-section>
        <q-card-actions>
          <q-btn
            label="Enviar"
            class="full-width cp-700 q-mb-sm"
            color="green-8"
            unelevated
            rounded
            no-caps
            @click="sendRecharge"
            :loading="loadingRecharges"
            :disable="loadingRecharges"
          />
          <q-btn
            label="Cancelar"
            class="full-width cp-700"
            color="red-8"
            unelevated
            rounded
            no-caps
            flat
            v-close-popup
            @click="cleanNewRechargeDataForm"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, watch, computed} from 'vue'

import {auth} from '../../firebase'

import {onAuthStateChanged} from 'firebase/auth'

import {compGetRecharges} from '@/composables/getRecharges'
import {compPostRecharges} from '@/composables/postRecharges'
import {compGetStudents} from '@/composables/getStudents'
import {compDataFormat} from '@/composables/dataFormat'
import {useQuasar} from 'quasar'

const $q = useQuasar()

const {getParentRecharges} = compGetRecharges()
const {postParentRecharges} = compPostRecharges()
const {getParentStudents} = compGetStudents()
const {formatDate, formatRechargeStatus} = compDataFormat()

const recharges = ref([])
const newRechargeData = ref({
  amount: null,
  students: [],
})
const newRechargeImg = ref(null)
const rechargeDialog = ref(false)
const loadingRecharges = ref(false)

const sendRecharge = async () => {
  loadingRecharges.value = true
  // 1. validate inputs
  let msg = []
  let total = 0
  if (newRechargeData.value.amount <= 0)
    msg.push('Debes ingresar el monto del a recarga')
  newRechargeData.value.students.forEach(student => {
    let am = parseFloat(student.amount)
    if (student.amount > 0) total += parseFloat(student.amount)
    if (am < 0 || isNaN(am))
      msg.push(
        `Debes ingresar un monto válido de recarga para: ${student.studentFullName}`
      )
  })
  if (newRechargeData.value.amount != total)
    msg.push('La distribución de montos de recarga, no es igual al monto total')
  if (!newRechargeImg.value)
    msg.push('Debes adjuntar la imagen del comprobante de pago')
  if (msg.length > 0) {
    msg.forEach(msg => {
      $q.notify({
        message: msg,
        color: 'red-8',
        icon: 'error',
        position: 'top',
        badgeColor: 'yellow-8',
        progress: true,
        timeout: 3000,
      })
    })
    loadingRecharges.value = false
    return
  }
  // 2. send data
  const rechResp = await postParentRecharges(
    newRechargeData.value,
    newRechargeImg.value
  )
  recharges.value.unshift(rechResp)
  // 3. clean new recharge data
  cleanNewRechargeDataForm()
  // 4. close dialog
  $q.notify({
    message: 'Solicitud de recarga enviada con éxito.',
    color: 'green-8',
    icon: 'error',
    position: 'top',
    badgeColor: 'yellow-8',
    progress: true,
    timeout: 3000,
  })
  rechargeDialog.value = false
  loadingRecharges.value = false
}

const cleanNewRechargeDataForm = () => {
  newRechargeData.value.amount = null
  newRechargeData.value.students.forEach(student => {
    student.amount = null
  })
  newRechargeImg.value = null
}

onAuthStateChanged(auth, async user => {
  if (user) {
    loadingRecharges.value = true
    recharges.value = await getParentRecharges(user.uid)
    loadingRecharges.value = false
    let myStudents = await getParentStudents(user.uid)
    myStudents.forEach(student => {
      newRechargeData.value.students.push({
        studentId: student.id,
        studentFullName: `${student.name} ${student.lastName}`,
        amount: null,
      })
    })
  }
})

const amountComputed = computed(() => {
  return newRechargeData.value.amount
})

watch(amountComputed, (newD, oldD) => {
  if (newRechargeData.value.students.length == 1) {
    newRechargeData.value.students[0].amount = newRechargeData.value.amount
  }
})

const columns = [
  {
    name: 'date',
    required: true,
    label: 'Fecha',
    align: 'left',
    field: 'date',
    sortable: true,
  },
  {
    name: 'amount',
    required: true,
    label: 'Monto',
    align: 'left',
    field: 'amount',
    sortable: true,
  },
  {
    name: 'status',
    required: true,
    label: 'Estatus',
    align: 'left',
    field: 'status',
    sortable: true,
  },
]
</script>
