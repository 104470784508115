import {createRouter, createWebHistory} from 'vue-router'

import {auth} from '../firebase'
import {onAuthStateChanged} from 'firebase/auth'

// LAYOUTS
import RootLayout from '@/layouts/RootLayout.vue'
import ParentsLayout from '@/layouts/ParentsLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'

// VIEWS
import HomeView from '@/views/RootViews/HomeView.vue'
import LoginView from '@/views/RootViews/LoginView.vue'
import RegisterView from '@/views/RootViews/RegisterView.vue'

import ParentsHomeView from '@/views/ParentsDashboardViews/ParentsHomeView.vue'
import ParentsChildrenView from '@/views/ParentsDashboardViews/ParentsChildrenView.vue'
import ParentsHistoryView from '@/views/ParentsDashboardViews/ParentsHistoryView.vue'
import ParentsRechargesView from '@/views/ParentsDashboardViews/ParentsRechargesView.vue'
import ParentsSettingsView from '@/views/ParentsDashboardViews/ParentsSettingsView.vue'
import ParentsOrderHistoryView from '@/views/ParentsDashboardViews/ParentsOrderHistoryView'
import ParentsCreateOrderView from '@/views/ParentsDashboardViews/ParentsCreateOrderView'

import AdminPosView from '@/views/AdminDashboardViews/AdminPosView.vue'
import AdminRechargesView from '@/views/AdminDashboardViews/AdminRechargesView.vue'
import AdminSalesView from '@/views/AdminDashboardViews/AdminSalesView.vue'
import AdminSettingsView from '@/views/AdminDashboardViews/AdminSettingsView.vue'
import AdminUsersView from '@/views/AdminDashboardViews/AdminUsersView.vue'
import AdminStudentsView from '@/views/AdminDashboardViews/AdminStudentsView.vue'
import AdminInventoryView from '@/views/AdminDashboardViews/AdminInventoryView.vue'
import AdminMenuView from '@/views/AdminDashboardViews/AdminMenuView.vue'
import AdminOrdersView from '@/views/AdminDashboardViews/AdminOrdersView.vue'

const routes = [
  {
    path: '/',
    component: RootLayout,
    children: [
      {
        path: '/',
        component: HomeView,
      },
      {
        path: '/login',
        component: LoginView,
      },
      {
        path: '/register',
        component: RegisterView,
      },
    ],
  },
  {
    path: '/parents',
    component: ParentsLayout,
    children: [
      {
        path: '/parents/home',
        component: ParentsHomeView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/parents/children',
        component: ParentsChildrenView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/parents/recharges',
        component: ParentsRechargesView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/parents/history',
        component: ParentsHistoryView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/parents/settings',
        component: ParentsSettingsView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/parents/orders',
        component: ParentsOrderHistoryView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/parents/orders/new/:studentId',
        component: ParentsCreateOrderView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '/admin/pos',
        component: AdminPosView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/recharges',
        component: AdminRechargesView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/sales',
        component: AdminSalesView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/orders',
        component: AdminOrdersView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/users',
        component: AdminUsersView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/settings',
        component: AdminSettingsView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/students',
        component: AdminStudentsView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/inventory',
        component: AdminInventoryView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/admin/menu',
        component: AdminMenuView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    onAuthStateChanged(auth, user => {
      if (user) next()
      else router.push('/login')
    })
  } else next()
})

export default router
