import {db, auth} from '../firebase'

import {collection, addDoc} from 'firebase/firestore'

import {compGetUserData} from '@/composables/getUserData'

const {getUserData} = compGetUserData()

export function compPostStudents() {
  async function postNewStudent(studentData) {
    try {
      const uid = auth.currentUser.uid
      const parentData = await getUserData(uid)
      const newStudentObject = {
        allowCredit: false,
        balance: 0,
        id: studentData.id,
        isActive: true,
        lastName: studentData.lastName,
        name: studentData.name,
        parentId: auth.currentUser.uid,
        parentLastName: parentData.name,
        parentName: parentData.lastName,
      }
      await addDoc(collection(db, 'students'), newStudentObject)
      return 'ok'
    } catch (err) {
      return 'err'
    }
  }
  return {
    postNewStudent,
  }
}
