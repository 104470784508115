import {db} from '../firebase'

import {doc, deleteDoc} from 'firebase/firestore'

export function compDeleteInventory() {
  async function deleteInventoryItem(id) {
    await deleteDoc(doc(db, 'inventory', id))
    return id
  }
  return {
    deleteInventoryItem,
  }
}
