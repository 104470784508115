<template>
  <q-page v-if="userType == 'admin'">
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Recargas</div>
      <div class="text-body2 cp-300">
        Revisa y administra las solicitudes de recarga.
      </div>
    </div>
    <div class="row q-py-md">
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-select
          label="Filtrar por estado"
          filled
          color="blue-8"
          emit-value
          map-options
          :options="[
            {label: 'Pendiente', value: 'pending'},
            {label: 'Aprobado', value: 'approved'},
            {label: 'Rechazado', value: 'rejected'},
          ]"
          v-model="filterStatus"
        >
          <template v-if="filterStatus" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="filterStatus = ''"
              class="cursor-pointer"
            />
          </template>
        </q-select>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          label="Filtrar por acudiente"
          filled
          color="blue-8"
          v-model="filterParent"
        >
          <template v-if="filterParent" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="filterParent = ''"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          label="Filtrar por correo"
          filled
          color="blue-8"
          v-model="filterEmail"
        >
          <template v-if="filterEmail" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="filterEmail = ''"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          filled
          mask="date"
          label="Filtrar por fecha"
          color="blue-8"
          v-model="filterDate"
        >
          <template v-if="filterDate" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="filterDate = ''"
              class="cursor-pointer"
            />
          </template>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="filterDate">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="blue-8"
                      flat
                      rounded
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>
    <div class="q-px-md">
      <q-table
        title="Historial de recargas"
        :rows="filterRecharges"
        :columns="columns"
        :loading="loadingData"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="blue-8"
                round
                dense
                unelevated
                icon="o_view_timeline"
                @click="reviewRecharge(props.row)"
              />
            </q-td>
            <q-td key="ownerFullName" :props="props">
              {{ props.row.ownerFullName }}
            </q-td>
            <q-td key="amount" :props="props">
              ${{ props.row.amount.toFixed(2) }}
            </q-td>
            <q-td key="email" :props="props">
              {{ props.row.email }}
            </q-td>
            <q-td key="date" :props="props">
              {{ formatDate(props.row.date) }}
            </q-td>
            <q-td key="status" :props="props">
              <q-badge
                :color="formatRechargeStatus(props.row.status).color"
                :label="formatRechargeStatus(props.row.status).label"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <q-dialog
      v-model="reviewModal"
      maximized
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="bg-grey-2">
        <div class="row justify-center">
          <div class="col-lg-10 col-xs-12">
            <q-card-section>
              <div class="row items-center justify-between">
                <div class="text-h5 cp-700">Revisión de recarga</div>
                <q-btn icon="close" flat color="blue-8" v-close-popup round />
              </div>
            </q-card-section>
            <q-card-section>
              <div class="row">
                <div class="col-lg-8 col-xs-12 q-px-md q-mb-md">
                  <q-img :src="selectedRecharge.img" class="rounded-borders" />
                </div>
                <div class="col-lg-4 col-xs-12 q-px-md">
                  <q-card flat class="cp-rounded-borders">
                    <q-card-section>
                      <q-badge
                        :label="
                          formatRechargeStatus(selectedRecharge.status).label
                        "
                        :color="
                          formatRechargeStatus(selectedRecharge.status).color
                        "
                        class="q-px-sm q-mb-sm"
                      />
                      <div class="text-h5">
                        Total de la recarga:
                        <span class="cp-700 text-blue"
                          >${{ selectedRecharge.amount.toFixed(2) }}</span
                        >
                      </div>
                    </q-card-section>
                    <q-separator />
                    <q-card-section>
                      <q-input
                        label="Acudiente"
                        filled
                        readonly
                        class="q-mb-sm"
                        v-model="selectedRecharge.ownerFullName"
                      />
                      <q-input
                        label="Correo electronico"
                        filled
                        readonly
                        class="q-mb-sm"
                        v-model="selectedRecharge.email"
                      />
                      <q-input
                        label="Fecha"
                        filled
                        readonly
                        class="q-mb-sm"
                        v-model="selectedRecharge.date"
                      />
                      <q-input
                        :label="student.studentFullName"
                        filled
                        readonly
                        class="q-mb-sm"
                        v-for="student in selectedRecharge.students"
                        :key="student.id"
                        v-model="student.amount"
                      />
                      <q-input
                        v-if="selectedRecharge.rejectionReason"
                        label="Motivo de rechazo"
                        v-model="selectedRecharge.rejectionReason"
                        type="textarea"
                        rows="3"
                        filled
                        readonly
                      />
                    </q-card-section>
                    <div v-if="selectedRecharge.status == 'pending'">
                      <q-separator />
                      <q-card-actions>
                        <q-btn
                          label="Aprobar recarga"
                          unelevated
                          rounded
                          color="green-8"
                          no-caps
                          class="full-width cp-700 q-mb-md"
                          @click="updateRec('approved')"
                          :loading="loadingData"
                          :disable="loadingData"
                        />
                        <q-input
                          label="Motivo de rechazo"
                          type="textarea"
                          rows="3"
                          v-model="rejectReason"
                          color="blue-8"
                          class="full-width q-mb-sm"
                          filled
                        />
                        <q-btn
                          label="Rechazar recarga"
                          unelevated
                          rounded
                          color="red-8"
                          no-caps
                          outline
                          class="full-width cp-700"
                          :disable="!rejectReason.trim() || loadingData"
                          :loading="loadingData"
                          @click="updateRec('rejected')"
                        />
                      </q-card-actions>
                    </div>
                  </q-card>
                </div>
              </div>
            </q-card-section>
          </div>
        </div>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue'

import {compGetRecharges} from '@/composables/getRecharges'
import {compUpdateRecharges} from '@/composables/updateRecharges'
import {compDataFormat} from '@/composables/dataFormat'

const {getAllRecharges} = compGetRecharges()
const {updateRechargeStatus} = compUpdateRecharges()
const {formatDate, formatRechargeStatus} = compDataFormat()

const loadingData = ref(true)
const selectedRecharge = ref(null)
const allRecharges = ref([])
const reviewModal = ref(false)
const filterStatus = ref('')
const filterParent = ref('')
const filterEmail = ref('')
const filterDate = ref('')
const rejectReason = ref('')
const userType = ref(null)

const reviewRecharge = recharge => {
  selectedRecharge.value = recharge
  reviewModal.value = true
}

const updateRec = async status => {
  loadingData.value = true
  await updateRechargeStatus(selectedRecharge.value, status, rejectReason.value)
  selectedRecharge.value.status = status
  loadingData.value = false
  reviewModal.value = false
}

const filterRecharges = computed(() => {
  let filtered = allRecharges.value.filter(
    recharge =>
      recharge.status.includes(filterStatus.value) &&
      recharge.ownerFullName.toLowerCase().includes(filterParent.value) &&
      recharge.email.toLowerCase().includes(filterEmail.value) &&
      recharge.date.includes(filterDate.value)
  )
  return filtered
})

onBeforeMount(async () => {
  userType.value = sessionStorage.getItem('cpUserType')
  allRecharges.value = await getAllRecharges()
  loadingData.value = false
})

const columns = [
  {
    name: 'ownerFullName',
    required: true,
    label: 'Acudiente',
    align: 'left',
    field: 'ownerFullName',
    sortable: true,
  },
  {
    name: 'amount',
    required: true,
    label: 'Monto',
    align: 'left',
    field: 'amount',
    sortable: true,
  },
  {
    name: 'email',
    required: true,
    label: 'Correo',
    align: 'left',
    field: 'email',
    sortable: true,
  },
  {
    name: 'date',
    required: true,
    label: 'Fecha',
    align: 'left',
    field: 'date',
    sortable: true,
  },
  {
    name: 'status',
    required: true,
    label: 'Estado',
    align: 'left',
    field: 'status',
    sortable: true,
  },
]
</script>
