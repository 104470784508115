<template>
  <q-page v-if="userType == 'admin'">
    <div class="q-pa-md">
      <div class="text-h4 cp-700">
        Ventas
        <span v-if="filterSales.length > 0 && startDateFilter && endDateFilter"
          >${{ calculateTotalSales.toFixed(2) }}</span
        >
      </div>
      <div class="text-body2 cp-300">Revisa y exporta tus ventas.</div>
    </div>
    <div class="row q-px-md">
      <q-btn
        label="Descargar ventas"
        @click="exportData"
        class="q-mx-sm cp-rounded-borders"
        icon-right="fas fa-file-excel"
        unelevated
        color="green-8"
        no-caps
      />
      <q-btn
        label="Descargar productos"
        @click="countProducts"
        class="q-ml-sm cp-rounded-borders"
        icon-right="fas fa-file-excel"
        unelevated
        color="green-8"
        no-caps
      />
    </div>

    <div class="row q-py-md">
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          v-model="studentFilter"
          label="Estudiante"
          filled
          color="blue-8"
        >
          <template v-if="studentFilter" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="studentFilter = ''"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          v-model="startDateFilter"
          filled
          mask="date"
          label="Filtrar por fecha (inicio)"
          color="blue-8"
          clearable
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="startDateFilter">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="blue-8"
                      flat
                      rounded
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          v-model="endDateFilter"
          filled
          mask="date"
          label="Filtrar por fecha (fin)"
          color="blue-8"
          clearable
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="endDateFilter">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="blue-8"
                      flat
                      rounded
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-select
          label="Filtar por método de pago"
          filled
          color="blue-8"
          :options="[
            {label: 'Huella', value: 'fingerprint'},
            {label: 'Efectivo', value: 'cash'},
            {label: 'Yappy', value: 'yappy'},
            // {label: 'Pedido', value: 'order'},
            {label: 'Tarjeta RFID', value: 'rfid'},
          ]"
          emit-value
          map-options
          v-model="methodFilter"
        >
          <template v-if="methodFilter" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="methodFilter = ''"
              class="cursor-pointer"
            />
          </template>
        </q-select>
      </div>
    </div>

    <div class="q-px-md">
      <q-table
        :rows="filterSales"
        :columns="columns"
        :loading="salesLoading"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td>
              <q-btn
                round
                @click="props.row.expand = !props.row.expand"
                :icon="props.row.expand ? 'remove' : 'add'"
                color="blue-8"
                unelevated
                size="xs"
              />
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="amount" :props="props">
              {{ props.row.amount }}
            </q-td>
            <q-td key="date" :props="props">
              {{ formatDate(props.row.date) }}
            </q-td>
            <q-td key="method" :props="props">
              {{ formatPaymentMethod(props.row.method) }}
            </q-td>
          </q-tr>
          <q-tr v-show="props.row.expand" :props="props">
            <q-td colspan="100%">
              <div
                class="text-left"
                v-for="(item, i) in props.row.cart"
                :key="i"
              >
                {{ item.amount }}x - {{ item.name }} - ${{
                  item.price.toFixed(2)
                }}
                = ${{ (item.price * item.amount).toFixed(2) }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue'

import exportFromJSON from 'export-from-json'

import {compGetSales} from '@/composables/getSales'
import {compDataFormat} from '@/composables/dataFormat'

const {getAllSales} = compGetSales()
const {formatPaymentMethod, formatDate} = compDataFormat()

const studentFilter = ref('')
const startDateFilter = ref('')
const endDateFilter = ref('')
const methodFilter = ref('')
const allSales = ref([])
const salesLoading = ref(true)
const userType = ref(null)

const exportData = () => {
  excelParser().exportDataFromJSON(formatExportSales())
}

const excelParser = () => {
  function exportDataFromJSON(data, fileName) {
    if (!data) return
    let fn = fileName
    try {
      const fileName = fn ? fn : 'reporte_de_ventas'
      const exportType = 'xls'
      exportFromJSON({data, fileName, exportType})
    } catch (e) {
      throw new Error('Parsing failed!')
    }
  }
  return {
    exportDataFromJSON,
  }
}

const formatExportSales = () => {
  const data = filterSales.value
  let formattedData = []
  data.forEach(sale => {
    let c = ''
    sale.cart.forEach((item, i) => {
      c += `${item.amount}x - ${item.name} - $${item.price.toFixed(2)} = $${(
        item.amount * item.price
      ).toFixed(2)}${i + 1 == sale.cart.length ? '' : ' | '}`
    })
    // let openHTML = '<div style="vertical-align: top">'
    let openHTML = '<div style="border: solid 1px black;">'
    let closeHTML = '</div>'
    let d = {
      fecha: `${openHTML}${sale.date}${closeHTML}`,
      nombre: `${openHTML}${sale.name}${closeHTML}`,
      monto: `${openHTML}${sale.amount}${closeHTML}`,
      metodo: `${openHTML}${formatPaymentMethod(sale.method)}${closeHTML}`,
      // compra: `${openHTML}${c}${closeHTML}`,
    }
    formattedData.push(d)
  })
  return formattedData
}

const countProducts = () => {
  const data = filterSales.value
  let products = []
  data.forEach(sale => {
    sale.cart.forEach(item => {
      if (item.id) {
        let found = products.find(i => i.barcode == item.id)
        if (found) found.amount += item.amount
        else
          products.push({
            amount: item.amount,
            barcode: item.id,
            name: item.name,
          })
      } else {
        if (item.name != 'Otros') {
          let menuName = `${item.name} - $${item.price}`.trim()
          let found = products.find(i => i.name == menuName)
          if (found) found.amount += item.amount
          else {
            products.push({
              amount: item.amount,
              name: menuName,
            })
          }
        }
      }
    })
  })
  excelParser().exportDataFromJSON(products, 'productos_vendidos')
}

const filterSales = computed(() => {
  let filtered = allSales.value.filter(
    sale =>
      sale.name.toLowerCase().includes(studentFilter.value.toLowerCase()) &&
      sale.method.includes(methodFilter.value) &&
      sale.date >= startDateFilter.value &&
      (endDateFilter.value ? sale.date <= endDateFilter.value : true)
  )
  return filtered
})

const calculateTotalSales = computed(() => {
  let total = 0
  filterSales.value.forEach(sale => {
    let amount = parseFloat(sale.amount.replace(/\$/g, ''))

    total += amount
  })
  return total
})

onBeforeMount(async () => {
  userType.value = sessionStorage.getItem('cpUserType')
  const salesResp = await getAllSales()
  allSales.value = salesResp
  salesLoading.value = false
})

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Estudiante',
    align: 'left',
    field: 'name',
    sortable: true,
  },
  {
    name: 'amount',
    required: true,
    label: 'Monto',
    align: 'left',
    field: 'amount',
    sortable: true,
  },
  {
    name: 'date',
    required: true,
    label: 'Fecha',
    align: 'left',
    field: 'date',
    sortable: true,
  },
  {
    name: 'method',
    required: true,
    label: 'Método de pago',
    align: 'left',
    field: 'method',
    sortable: true,
  },
]
</script>
