import script from "./ParentsCreateOrderView.vue?vue&type=script&setup=true&lang=js"
export * from "./ParentsCreateOrderView.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBanner,QSelect,QCard,QCardSection,QSeparator,QList,QItem,QItemSection,QBtn,QItemLabel,QCardActions,QDialog,QScrollArea,QExpansionItem,QSpace});qInstall(script, 'directives', {ClosePopup});
