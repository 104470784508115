<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Compras</div>
      <div class="text-body2 cp-300">
        Revisa el historial de compras de tus hijos.
      </div>
    </div>
    <div class="row q-py-md">
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-select
          label="Filtar por estudiante"
          filled
          color="blue-8"
          :options="studentsSelectOptions"
          v-model="childFilter"
        >
          <template v-if="childFilter" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="childFilter = ''"
              class="cursor-pointer"
            />
          </template>
        </q-select>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          filled
          v-model="dateFilterStart"
          mask="date"
          label="Filtrar por fecha (inicio)"
          color="blue-8"
        >
          <template v-if="dateFilterStart" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="dateFilterStart = ''"
              class="cursor-pointer"
            />
          </template>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="dateFilterStart">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="blue-8"
                      flat
                      rounded
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          filled
          v-model="dateFilterEnd"
          mask="date"
          label="Filtrar por fecha (fin)"
          color="blue-8"
        >
          <template v-if="dateFilterEnd" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="dateFilterEnd = ''"
              class="cursor-pointer"
            />
          </template>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="dateFilterEnd">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="blue-8"
                      flat
                      rounded
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>
    <div class="q-px-md">
      <q-table
        title="Historial de compras"
        :rows="filterStudentsSales"
        :columns="columns"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
        :loading="salesLoading"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="blue-8"
                round
                dense
                unelevated
                @click="props.row.expand = !props.row.expand"
                :icon="props.row.expand ? 'remove' : 'add'"
              />
            </q-td>
            <q-td key="student" :props="props">
              {{ props.row.student }}
            </q-td>
            <q-td key="total" :props="props">
              ${{ props.row.total.toFixed(2) }}
            </q-td>
            <q-td key="date" :props="props">
              {{ formatDate(props.row.date) }}
            </q-td>
          </q-tr>
          <q-tr v-show="props.row.expand" :props="props">
            <q-td colspan="100%">
              <div class="text-left" v-for="item in props.row.cart">
                {{ item.amount }}x - {{ item.name }} - ${{
                  item.price.toFixed(2)
                }}
                = ${{ (item.amount * item.price).toFixed(2) }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'

import {auth} from '../../firebase'

import {onAuthStateChanged} from 'firebase/auth'

import {compGetStudents} from '@/composables/getStudents'
import {compGetSales} from '@/composables/getSales'
import {compDataFormat} from '@/composables/dataFormat'

const {getParentStudents} = compGetStudents()
const {getParentStudensSales} = compGetSales()
const {formatDate} = compDataFormat()

const salesLoading = ref(true)
const dateFilterStart = ref('')
const dateFilterEnd = ref('')
const childFilter = ref('')
const students = ref([])
const studentsSelectOptions = ref([])
const allSales = ref([])

onAuthStateChanged(auth, async user => {
  if (user) {
    students.value = await getParentStudents(user.uid)
    allSales.value = await getParentStudensSales(user.uid)
    students.value.forEach(student =>
      studentsSelectOptions.value.push(`${student.name} ${student.lastName}`)
    )
    salesLoading.value = false
  }
})

const filterStudentsSales = computed(() => {
  return allSales.value.filter(
    sale =>
      sale.student.includes(childFilter.value) &&
      sale.date >= dateFilterStart.value &&
      (dateFilterEnd.value ? sale.date <= dateFilterEnd.value : true)
  )
})

onMounted(() => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const student = urlParams.get('student')
  if (student) childFilter.value = student
})

const columns = [
  {
    name: 'student',
    required: true,
    label: 'Estudiante',
    align: 'left',
    field: 'studentName',
    sortable: true,
  },
  {
    name: 'total',
    required: true,
    label: 'Total',
    align: 'left',
    field: 'total',
    sortable: true,
  },
  {
    name: 'date',
    required: true,
    label: 'Fecha',
    align: 'left',
    field: 'date',
    sortable: true,
  },
]
</script>
