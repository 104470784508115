<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Pedidos</div>
      <div class="text-body2 cp-300">Revisa y completa pedidos.</div>
    </div>

    <div class="row q-py-md">
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          label="Estudiante"
          filled
          color="blue-8"
          v-model="studentFilter"
        >
          <template v-if="studentFilter" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="studentFilter = ''"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>
      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-input
          v-model="dateFilter"
          filled
          mask="date"
          label="Filtrar por fecha"
          color="blue-8"
          clearable
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="dateFilter">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="blue-8"
                      flat
                      rounded
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div class="col-lg-3 col-xs-12 q-px-md q-mb-sm">
        <q-select
          label="Filtar por estado"
          filled
          color="blue-8"
          :options="[
            {label: 'Entregado', value: 'delivered'},
            {label: 'Por entregar', value: 'delivery'},
          ]"
          emit-value
          map-options
          v-model="statusFilter"
        >
          <template v-if="statusFilter" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="statusFilter = ''"
              class="cursor-pointer"
            />
          </template>
        </q-select>
      </div>
    </div>
    <div class="row" v-if="dateFilter">
      <div class="col-lg-3 q-px-md q-mb-md">
        <q-btn
          label="Descargar productos"
          unelevated
          class="full-width cp-700 cp-rounded-borders"
          color="green-8"
          icon-right="fas fa-file-excel"
          @click="downloadOrdersItems"
        />
      </div>
      <div class="col-lg-3 q-px-md q-mb-md">
        <q-btn
          label="Descargar pedidos"
          unelevated
          class="full-width cp-700 cp-rounded-borders"
          color="green-8"
          icon-right="fas fa-file-excel"
          @click="downloadOrders"
        />
      </div>
    </div>
    <div class="q-px-md">
      <q-table
        title="Historial de pedidos"
        :rows="filterOrderByDate"
        :columns="columns"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
        :loading="ordersLoading"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="blue-8"
                round
                dense
                unelevated
                @click="props.row.expand = !props.row.expand"
                :icon="props.row.expand ? 'remove' : 'add'"
              />
            </q-td>
            <q-td key="complete" :props="props">
              <q-btn
                icon="check"
                unelevated
                color="green-8"
                round
                size="xs"
                :disable="props.row.status == 'delivered'"
                @click="completeOrder(props.row)"
              />
            </q-td>
            <q-td key="student" :props="props">
              {{ props.row.student }}
            </q-td>
            <q-td key="purchaseDate" :props="props">
              {{ formatDate(props.row.purchaseDate) }}
            </q-td>
            <q-td key="deliveryDate" :props="props">
              {{ formatDate(props.row.deliveryDate) }}
            </q-td>
            <q-td key="deliveryTime" :props="props">
              {{ props.row.deliveryTime }}
            </q-td>
            <q-td key="deliveryPlace" :props="props">
              {{ props.row.deliveryPlace }}
            </q-td>
            <q-td key="status" :props="props">
              <q-badge
                :label="formatOrderStatus(props.row.status).label"
                :color="formatOrderStatus(props.row.status).color"
              />
            </q-td>
          </q-tr>
          <q-tr v-show="props.row.expand" :props="props">
            <q-td colspan="100%">
              <div class="text-left" v-for="item in props.row.cart">
                {{ item.amount }}x - {{ item.name }} - ${{
                  item.price.toFixed(2)
                }}
                = ${{ (item.amount * item.price).toFixed(2) }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue'

import exportFromJSON from 'export-from-json'

import {compGetOrders} from '@/composables/getOrders'
import {compDataFormat} from '@/composables/dataFormat'
import {compUpdateOrder} from '@/composables/updateOrders'

const {getAllOrders} = compGetOrders()
const {formatDate, formatOrderStatus} = compDataFormat()
const {completeOrderById} = compUpdateOrder()

const orders = ref([])
const ordersLoading = ref(false)
const studentFilter = ref('')
const dateFilter = ref('')
const statusFilter = ref('')

const completeOrder = async order => {
  ordersLoading.value = true
  const orderResp = await completeOrderById(order.id)
  if (orderResp == 'ok') order.status = 'delivered'
  ordersLoading.value = false
}

const downloadOrdersItems = () => {
  let items = []
  for (const order of filterOrderByDate.value) {
    for (const cart of order.cart) {
      if (cart.id) {
        let found = items.find(item => item.id == cart.id)
        if (found) {
          found.cantidad += cart.amount
        } else {
          items.push({
            codigo_de_barras: cart.id,
            producto: cart.name,
            cantidad: cart.amount,
          })
        }
      } else {
        let iName = `${cart.name.trim()} - $${cart.price.toFixed(2)}`
        let found = items.find(item => item.producto == iName)
        if (found) {
          found.cantidad += cart.amount
        } else {
          items.push({
            producto: iName,
            cantidad: cart.amount,
            codigo_de_barras: '',
          })
        }
      }
    }
  }
  exportData(items)
}

const downloadOrders = () => {
  let orders = []
  filterOrderByDate.value.forEach(order => {
    let purchase = ''
    order.cart.forEach(item => {
      purchase += `${item.amount}x - ${item.name}${
        item.id ? '' : ` - $${item.price.toFixed(2)}`
      }<br/>`
    })
    orders.push({
      estudiante: `<div style="vertical-align: top">${order.student}</div>`,
      compra: purchase,
    })
  })
  exportData(orders)
}

const exportData = data => {
  excelParser().exportDataFromJSON(data)
}

const excelParser = () => {
  function exportDataFromJSON(data) {
    if (!data) return
    try {
      const fileName = `productos_pedidos_${dateFilter.value}`
      const exportType = 'xls'
      exportFromJSON({data, fileName, exportType})
    } catch (e) {
      throw new Error('Parsing failed!')
    }
  }
  return {
    exportDataFromJSON,
  }
}

const filterOrderByDate = computed(() => {
  return orders.value.filter(
    order =>
      order.status.includes(statusFilter.value) &&
      order.deliveryDate.includes(dateFilter.value) &&
      order.student.toLowerCase().includes(studentFilter.value.toLowerCase())
  )
})

onBeforeMount(async () => {
  ordersLoading.value = true
  orders.value = await getAllOrders()
  ordersLoading.value = false
})

const columns = [
  {
    name: 'complete',
    label: 'Completar',
    align: 'center',
  },
  {
    name: 'student',
    required: true,
    label: 'Estudiante',
    align: 'left',
    field: 'studentName',
    sortable: true,
  },
  {
    name: 'purchaseDate',
    required: true,
    label: 'Fecha de orden',
    align: 'left',
    field: 'purchaseDate',
    sortable: true,
  },
  {
    name: 'deliveryDate',
    required: true,
    label: 'Fecha de entrega',
    align: 'left',
    field: 'deliveryDate',
    sortable: true,
  },
  {
    name: 'deliveryTime',
    required: true,
    label: 'Hora de entrega',
    align: 'left',
    field: 'deliveryTime',
    sortable: true,
  },
  {
    name: 'deliveryPlace',
    required: true,
    label: 'Lugar de entrega',
    align: 'left',
    field: 'deliveryPlace',
    sortable: true,
  },
  {
    name: 'status',
    required: true,
    label: 'Estado',
    align: 'left',
    field: 'status',
    sortable: true,
  },
]
</script>
