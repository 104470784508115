<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Menú</div>
      <div class="text-body2 cp-300 q-mb-md">Gestiona tus menus diarios.</div>
      <div class="row justify-end" v-if="!isRoe">
        <q-btn
          label="Agregar"
          rounded
          color="green-8"
          unelevated
          no-caps
          icon-right="add"
          class="q-px-lg"
          @click="openMenuDialog('new')"
        />
      </div>

      <q-table
        grid
        :rows="allMenu"
        row-key="name"
        :filter="filter"
        :pagination="{rowsPerPage: 8}"
        :loading="loadingMenu"
      >
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Buscar"
            filled
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>

        <template v-slot:item="props">
          <div class="q-pa-xs col-xs-12 col-sm-6 col-md-3">
            <q-card flat class="cp-rounded-borders">
              <q-card-section>
                <div class="row">
                  <div class="col-md-4 col-xs-4">
                    <q-img
                      :src="
                        props.row.img
                          ? props.row.img
                          : require('@/assets/menu_placeholder.png')
                      "
                      class="cp-rounded-borders"
                    />
                  </div>
                  <div class="col-md-8 col-xs-8 q-pl-md">
                    <div class="text-caption cp-400 q-mb-xs">
                      {{ formatDate(props.row.date) }}
                    </div>
                    <div class="text-subtitle cp-700">
                      {{ props.row.name }}
                    </div>
                    <div class="text-caption q-mb-sm">
                      {{ props.row.description }}
                    </div>
                  </div>
                </div>
              </q-card-section>
              <q-separator />
              <q-card-section>
                <div class="row text-center">
                  <div class="col-md-6">
                    <div class="text-body2">
                      Prescolar:
                      <span class="cp-700 text-blue-8"
                        >${{ parseFloat(props.row.babyPrice).toFixed(2) }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="text-body2">
                      Niño:
                      <span class="cp-700 text-blue-8"
                        >${{ parseFloat(props.row.kidPrice).toFixed(2) }}</span
                      >
                    </div>
                  </div>

                  <div class="col-md-12 q-mt-sm">
                    <div class="text-body2">
                      Adulto:
                      <span class="cp-700 text-blue-8"
                        >${{
                          parseFloat(props.row.adultPrice).toFixed(2)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </q-card-section>
              <q-separator />
              <q-card-actions align="center">
                <q-btn
                  label="Editar"
                  flat
                  color="blue-8"
                  no-caps
                  class="full-width cp-600"
                  rounded
                  @click="openMenuDialog('edit', props.row)"
                />
              </q-card-actions>
            </q-card>
          </div>
        </template>
      </q-table>
    </div>
    <q-dialog v-model="newMenuDialog">
      <q-card
        flat
        class="cp-rounded-borders"
        style="width: 700px; max-width: 85vw"
      >
        <q-card-section>
          <div class="text-h5 cp-700">
            {{ dialogSaveAction == 'new' ? 'Agregar' : 'Editar' }} menú
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="row">
            <div class="col-lg-6 col-xs-12 q-mb-sm">
              <q-input
                label="Nombre *"
                filled
                color="blue-8"
                class="q-mb-sm"
                v-model="menuData.name"
                :rules="[val => !!val.trim() || 'Este campo es obligatorio']"
                hide-bottom-space
              />
              <q-input
                label="Descripción *"
                filled
                color="blue-8"
                class="q-mb-sm"
                type="textarea"
                rows="3"
                v-model="menuData.description"
                :rules="[val => !!val.trim() || 'Este campo es obligatorio']"
                hide-bottom-space
              />
              <q-input
                label="Precio prescolar *"
                type="number"
                filled
                color="blue-8"
                class="q-mb-sm"
                v-model="menuData.babyPrice"
                :rules="[val => !!val || 'Este campo es obligatorio']"
                hide-bottom-space
              >
                <template v-slot:prepend>
                  <q-icon name="attach_money" />
                </template>
              </q-input>
              <q-input
                label="Precio niño *"
                type="number"
                filled
                color="blue-8"
                class="q-mb-sm"
                v-model="menuData.kidPrice"
                :rules="[val => !!val || 'Este campo es obligatorio']"
                hide-bottom-space
              >
                <template v-slot:prepend>
                  <q-icon name="attach_money" />
                </template>
              </q-input>
              <q-input
                label="Precio adulto *"
                type="number"
                filled
                color="blue-8"
                class="q-mb-sm"
                v-model="menuData.adultPrice"
                :rules="[val => !!val || 'Este campo es obligatorio']"
                hide-bottom-space
              >
                <template v-slot:prepend>
                  <q-icon name="attach_money" />
                </template>
              </q-input>

              <q-file
                filled
                color="blue-8"
                v-model="uploadImage"
                label="Imagen"
                v-if="!isRoe"
                accept="image/jpeg,image/png"
              >
                <template v-slot:prepend>
                  <q-icon name="attach_file" />
                </template>
              </q-file>
            </div>
            <div class="col-lg-6 col-xs-12">
              <div class="row justify-center">
                <q-date flat v-model="menuData.date" />
              </div>
            </div>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="row items-center justify-between">
            <q-btn
              icon="delete"
              label="Eliminar"
              rounded
              color="red-8"
              flat
              class="cp-700 q-px-lg q-ml-xs"
              no-caps
              @click="deleteFromMenu(menuData.id)"
              :disable="loadingMenu"
              :loading="loadingMenu"
              size="sm"
              v-if="dialogSaveAction == 'edit'"
            />
            <div v-else />
            <div>
              <q-btn
                label="Guardar"
                unelevated
                color="green-8"
                rounded
                class="cp-700 q-px-lg q-mr-xs"
                no-caps
                @click="saveAction"
                :disable="loadingMenu"
                :loading="loadingMenu"
              />
              <q-btn
                label="Cancelar"
                unelevated
                color="grey-8"
                rounded
                class="cp-700 q-px-lg q-ml-xs"
                no-caps
                outline
                v-close-popup
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue'

import {compPostMenu} from '@/composables/postMenu'
import {compGetMenu} from '@/composables/getMenu'
import {compUpdateMenu} from '@/composables/updateMenu'
import {compDeleteMenu} from '@/composables/deleteMenu'
import {compDataFormat} from '@/composables/dataFormat'
import {useQuasar} from 'quasar'

const $q = useQuasar()

let isElectron = require('is-electron')
var isRoe
if (isElectron()) {
  isRoe = true
}

const {getAllMenu} = compGetMenu()
const {postMenuItem} = compPostMenu()
const {updateMenuData} = compUpdateMenu()
const {deleteMenu} = compDeleteMenu()
const {formatDate} = compDataFormat()

const filter = ref('')
const newMenuDialog = ref(false)
const menuData = ref({
  name: '',
  description: '',
  kidPrice: null,
  adultPrice: null,
  babyPrice: null,
  date: '',
})
const uploadImage = ref(null)
const dialogSaveAction = ref('')
const loadingMenu = ref(true)
const allMenu = ref([])

const saveAction = () => {
  if (dialogSaveAction.value == 'new') sendMenu()
  if (dialogSaveAction.value == 'edit') updateMenu()
}

const updateMenu = async () => {
  if (!validateMenuForm()) return
  loadingMenu.value = true
  const menuResp = await updateMenuData(menuData.value, uploadImage.value)
  allMenu.value.find(menu => {
    if (menu.id == menuResp.id) menu.img = menuResp.img
  })
  uploadImage.value = null
  newMenuDialog.value = false
  loadingMenu.value = false
}

const deleteFromMenu = async id => {
  loadingMenu.value = true
  try {
    let menuResp = await deleteMenu(id)
    allMenu.value = allMenu.value.filter(menu => menu.id != menuResp)
  } catch (err) {
    alert('Ocurrio un error')
  }
  newMenuDialog.value = false
  loadingMenu.value = false
}

const sendMenu = async () => {
  if (!validateMenuForm()) return
  loadingMenu.value = true
  const menuResp = await postMenuItem(menuData.value, uploadImage.value)
  allMenu.value.unshift(menuResp)
  allMenu.value = allMenu.value.sort((a, b) => {
    if (a.date < b.date) return 1
    if (a.date > b.date) return -1
    return 0
  })
  newMenuDialog.value = false
  loadingMenu.value = false
  cleanNewMenuForm()
}

const cleanNewMenuForm = () => {
  menuData.value.name = ''
  menuData.value.description = ''
  menuData.value.kidPrice = null
  menuData.value.adultPrice = null
  menuData.value.babyPrice = null
  menuData.value.date = ''
  uploadImage.value = null
}

const openMenuDialog = (action, menu) => {
  dialogSaveAction.value = action
  if (action == 'edit') menuData.value = menu
  if (action == 'new') {
    menuData.value = {
      name: '',
      description: '',
      kidPrice: null,
      adultPrice: null,
      date: '',
    }
  }
  newMenuDialog.value = true
}

const validateMenuForm = () => {
  let msg = []
  if (!menuData.value.date)
    msg.push('Debes seleccionar una fecha del calendario.')
  if (
    !menuData.value.name.trim() ||
    !menuData.value.description.trim() ||
    !menuData.value.kidPrice ||
    !menuData.value.adultPrice ||
    !menuData.value.babyPrice
  )
    msg.push('Debes llenar los campos obligatorios.')
  if (msg.length > 0) {
    msg.forEach(msg => {
      $q.notify({
        message: msg,
        color: 'red-8',
        icon: 'error',
        position: 'top',
        badgeColor: 'yellow-8',
        progress: true,
        timeout: 3000,
      })
    })
    return false
  }
  return true
}

onBeforeMount(async () => {
  allMenu.value = await getAllMenu()
  loadingMenu.value = false
})
</script>
